import { makeAutoObservable, reaction } from 'mobx';
import { formatNumber } from '@/utils/numbers';
import { TaxpayerInfo } from '@/stores/domain/single-taxpayer/types';
import { formatTaxpayerInfo } from '@/stores/domain/single-taxpayer/helpers';
import taxpayerApi from '@/api/domain/taxpayer';
import { Period } from '@/stores/core/Period';
import router from '@/router';
import { i18n } from '@/i18n';

export class InfoStore {
  tin: string | null = null;
  data: TaxpayerInfo | null = null;
  isLoading = false;

  private periodDisposer?: () => void;

  constructor(private period: Period) {
    makeAutoObservable(this);
  }

  init(tin: string) {
    this.tin = tin;
    this.periodDisposer = reaction(
      () => this.period.asParams,
      () => this.fetch()
    );
  }

  destroy() {
    this.periodDisposer && this.periodDisposer();
    this.data = null;
    this.isLoading = false;
    this.tin = null;
  }

  get delta() {
    if (!this.data) return 0;
    return this.data.accountbalance - this.data.ndsbalance;
  }

  async fetch() {
    this.isLoading = true;
    try {
      const response = await taxpayerApi.getOne({
        ...this.period.asParams,
        tin: this.tin,
      });

      this.data = response.data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }
}

export class InfoViewStore {
  constructor(private info: InfoStore) {
    makeAutoObservable(this);
  }

  get data() {
    if (!this.info.data) return null;
    return formatTaxpayerInfo(this.info.data);
  }

  get delta() {
    return (
      (this.info.delta >= 0 ? '+' : '-') +
      ' ' +
      formatNumber(Math.abs(this.info.delta), { fractionDigits: 2 })
    );
  }

  get yearDynamics() {
    if (!this.info?.data) return [[], [], []];
    const items = [...this.info.data.listOfVat];
    items.sort((a, b) => a.quarter - b.quarter);
    return [
      items.map((item) => item.ndsbalance),
      items.map((item) => item.ndspaidinvin),
      items.map((item) => item.ndsTotalOut),
    ];
  }

  get warnings() {
    if (!this.data) return [];
    const prefix = (path: string) =>
      i18n.t(`page_taxpayer.main.warnings.${path}`) as string;
    const items: { label: string; status?: any }[] = [];
    if (this.data.escrowAccountType.value) {
      items.push({
        label: prefix('escrow'),
        status: this.data.escrowAccountType,
      });
    }
    if (this.data.errorKkmRisk) items.push({ label: prefix('kkm_risk') });
    if (
      this.data.escrowAccountType.value === 'MANDATORY' &&
      this.data.saldoNdsRisk
    )
      items.push({ label: prefix('vat_escrow_delta_risk') });
    if (this.data.stopOperationRisk)
      items.push({
        label: prefix('stop_operation_risk'),
      });
    return items;
  }

  get outcomeVat() {
    if (!this.data) return [];
    const prefix = 'page_taxpayer.main.outcome';
    return [
      {
        header: `${prefix}.total`,
        value: this.data.ndstotalout,
        info: `${prefix}.total_info`,
      },
      {
        header: `${prefix}.invoice`,
        value: this.data.ndsinvout,
        info: `${prefix}.invoice_info`,
        onClick: () => {
          router.push({
            path: `/app/taxpayers/${this.info.tin}/invoices`,
            query: { seller: 'true' },
          });
        },
      },
      {
        header: `${prefix}.receipt`,
        value: this.data.ndsreceiptout,
        info: `${prefix}.receipt_info`,
        onClick: () => {
          router.push(`/app/taxpayers/${this.info.tin}/receipts`);
        },
      },
    ];
  }
  get incomeVat() {
    if (!this.data) return [];
    const prefix = 'page_taxpayer.main.income';
    return [
      {
        header: `${prefix}.payed`,
        value: this.data.ndspaidinvin,
        info: `${prefix}.payed_info`,
        onClick: () => {
          router.push({
            path: `/app/taxpayers/${this.info.tin}/invoices`,
            query: { seller: 'false', paymentStatuses: 'PAID,PARTIALLY_PAID' },
          });
        },
      },
      {
        header: `${prefix}.total`,
        value: this.data.ndsinvin,
        info: `${prefix}.total_info`,
        onClick: () => {
          router.push({
            path: `/app/taxpayers/${this.info.tin}/invoices`,
            query: { seller: 'false' },
          });
        },
      },
      {
        header: `${prefix}.not_payed`,
        value: this.data.ndsnotpaidinvin,
        info: `${prefix}.not_payed_info`,
        onClick: () => {
          router.push({
            path: `/app/taxpayers/${this.info.tin}/invoices`,
            query: {
              seller: 'false',
              paymentStatuses: 'NOT_PAID,PARTIALLY_PAID',
            },
          });
        },
      },
    ];
  }
}
