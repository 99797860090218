import { RouteConfig } from 'vue-router';

const View = () => import('@/views/app/violations.vue');
const Index = () => import('@/views/app/violations/index.vue');
const Taxpayers = () => import('@/views/app/violations/taxpayers.vue');
const Banks = () => import('@/views/app/violations/banks.vue');

const violationsRoutes: Array<RouteConfig> = [
  {
    path: 'violations',
    component: View,
    children: [
      {
        path: '',
        name: 'violations-index',
        component: Index,
        redirect: { name: 'violations-taxpayers' },
      },
      {
        path: 'taxpayers',
        name: 'violations-taxpayers',
        component: Taxpayers,
      },
      {
        path: 'banks',
        name: 'violations-banks',
        component: Banks,
      },
    ],
  },
];

export default violationsRoutes;
