import { baseApi } from '@/api/base';
import { PageableResponse } from '@/types/pagination';
import {
  SaldoStatistics,
  SaldoVedStatistics,
} from '@/stores/domain/statistics/types';

const prefix = '/commons/no';

const statisticsApi = {
  getSaldo: (params: any) =>
    baseApi.get<PageableResponse<SaldoStatistics>>(`${prefix}/saldo`, {
      params,
    }),
  getSaldoVed: (params: any) =>
    baseApi.get<PageableResponse<SaldoVedStatistics>>(`${prefix}/ved/saldo`, {
      params,
    }),
};

export default statisticsApi;
