export const InvoiceStatuses = [
  'CREATED',
  'DELIVERED',
  'CANCELED',
  'CANCELED_BY_OGD',
  'REVOKED',
  'IMPORTED',
  'DRAFT',
  'FAILED',
  'DELETED',
  'DECLINED',
] as const;

export const InvoiceTypes = ['OUTCOME', 'INCOME'] as const;

export const DocTypes = [
  'ORDINARY_INVOICE',
  'FIXED_INVOICE',
  'ADDITIONAL_INVOICE',
] as const;

export const CounterpartyTypes = ['BUYER', 'SELLER'] as const;

export const PaymentStatusTypes = [
  'PAID',
  'NOT_PAID',
  'PARTIALLY_PAID',
] as const;

export type InvoiceType = (typeof InvoiceTypes)[number];
export type DocType = (typeof DocTypes)[number];
export type CounterpartyType = (typeof CounterpartyTypes)[number];
export type InvoiceStatus = (typeof InvoiceStatuses)[number];
export type PaymentStatusType = (typeof PaymentStatusTypes)[number];

export const InvoiceStatusLabel: Record<InvoiceStatus, string> =
  InvoiceStatuses.reduce((labels, l) => {
    labels[l] = `terms.invoice.inner_status.${l}`;
    return labels;
  }, {} as Record<InvoiceStatus, string>);

export const InvoiceStatusColor: Record<InvoiceStatus, string> = {
  CREATED: 'neutral',
  DELIVERED: 'success',
  CANCELED: 'neutral',
  CANCELED_BY_OGD: 'neutral',
  REVOKED: 'neutral',
  IMPORTED: 'neutral',
  DRAFT: 'neutral',
  FAILED: 'neutral',
  DELETED: 'neutral',
  DECLINED: 'neutral',
};

export const InvoiceDocTypeLabel: Record<DocType, string> = DocTypes.reduce(
  (labels, l) => {
    labels[l] = `terms.invoice.type.${l}`;
    return labels;
  },
  {} as Record<DocType, string>
);

export const InvoiceDocTypeColor: Record<DocType, string> = {
  ORDINARY_INVOICE: 'success',
  FIXED_INVOICE: 'neutral',
  ADDITIONAL_INVOICE: 'info',
};

export const InvoiceCardDocType: Record<DocType, string> = DocTypes.reduce(
  (labels, l) => {
    labels[l] = `terms.invoice.type.${l}`;
    return labels;
  },
  {} as Record<DocType, string>
);

export const PaymentStatusLabel: Record<PaymentStatusType, string> =
  PaymentStatusTypes.reduce((labels, l) => {
    labels[l] = `terms.invoice.payment_status.${l}`;
    return labels;
  }, {} as Record<PaymentStatusType, string>);

export const PaymentStatusColor: Record<PaymentStatusType, string> = {
  PAID: 'success',
  NOT_PAID: 'danger',
  PARTIALLY_PAID: 'accent',
};
