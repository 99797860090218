import { makeAutoObservable, reaction } from 'mobx';
import { BankViolation } from '@/stores/domain/violations/types';
import violationApi from '@/api/domain/violation';
import { Filter } from '@/stores/core/Filter';
import { Period } from '@/stores/core/Period';

interface FilterState {
  search: string;
  page: number;
  size: number;
  sort: {
    prop: string;
    direction: 'asc' | 'desc';
  };
}

export class BankListStore {
  data: BankViolation[] = [];
  isLoading = false;
  maxPage = 0;
  filter = new Filter<FilterState>(
    {
      search: '',
      page: 0,
      size: 20,
      sort: { prop: 'statementDate', direction: 'desc' },
    },
    { debounced: ['search'] }
  );

  private filterDisposer?: () => void;
  private period: Period;
  private periodDisposer?: () => void;

  constructor(period: Period) {
    makeAutoObservable(this);
    this.period = period;
  }

  init() {
    this.filterDisposer = this.filter.watch(() => this.fetch());
    this.periodDisposer = reaction(
      () => this.period.asParams,
      () => {
        this.filter.setSilent({ page: 0 });
        this.fetch();
      }
    );
  }

  destroy() {
    this.periodDisposer && this.periodDisposer();
    this.filterDisposer && this.filterDisposer();
    this.isLoading = false;
    this.data = [];
    this.maxPage = 0;
    this.filter.reset();
  }

  async fetch() {
    this.isLoading = true;
    try {
      const response = await violationApi.getBanks(this.getParams());

      this.maxPage = response.data.totalPages - 1;
      this.data = response.data.content;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  private getParams() {
    const { page, size, search, sort } = this.filter.value;
    return {
      ...this.period.asParams,
      pageNum: page,
      pageSize: size,
      ...(search && { search }),
      ...(sort.prop && {
        columnSort: sort.prop,
        order: sort.direction.toUpperCase(),
      }),
    };
  }
}
