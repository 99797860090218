<template>
  <div :class="$style.wrapper" v-if="isVisible">
    <quarter-selector
      :value="state.quarter"
      @change="state.setQuarter($event)"
    />
    <year-selector :value="state.year" @change="state.setYear($event)" />
  </div>
  <div v-else></div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import QuarterSelector from '@/components/QuarterSelector.vue';
import YearSelector from '@/components/YearSelector.vue';
import stores from '@/stores';
import { Observer } from 'mobx-vue';

@Observer
@Component({
  components: { YearSelector, QuarterSelector },
})
export default class PeriodSelector extends Vue {
  IGNORE_ROUTES = ['/app/statistics'];

  state = stores.period;

  get isVisible() {
    return !this.IGNORE_ROUTES.includes(this.$route.path);
  }
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 1.6rem;
  }
}
</style>
