<template>
  <div
    :class="$style.wrapper"
    :style="{ 'grid-template-columns': `repeat(${cols},1fr)` }"
  >
    <dtt-value-card
      v-for="item in items"
      :key="item.header"
      :header="$t(item.header)"
      :value="item.value"
      :value-color="item?.color || ''"
      @click.native="item.onClick && item.onClick()"
      :style="{ cursor: item.onClick ? 'pointer' : 'default' }"
    >
      <template #header>
        <div :class="$style.header">
          <span class="dtt-value-card__header">
            {{ $t(item.header) }}
          </span>
          <dtt-hint :hint="$t(item.info)" area-y="top" />
        </div>
      </template>
      <template #content>
        <NumberDeltaWrap :delta="item.delta">
          <span class="dtt-value-card__value">{{ item.value }}</span>
        </NumberDeltaWrap>
      </template>
    </dtt-value-card>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ValueWrap from '@/components/ui/ValueWrap.vue';
import NumberDeltaWrap from '@/components/ui/NumberDeltaWrap.vue';
import { SummaryViewItem } from '@/stores/domain/summary/types';

@Component({
  components: { NumberDeltaWrap, ValueWrap },
})
export default class SummaryInfoRow extends Vue {
  @Prop({ type: Number, default: 2 }) cols!: number;
  @Prop({ type: Array, default: () => [] })
  items!: SummaryViewItem[];
}
</script>

<style lang="scss" module>
.wrapper {
  display: grid;
  grid-column-gap: 2.4rem;
}
.header {
  display: flex;
  align-items: center;

  & > *:last-child {
    margin-left: 1rem;
    margin-bottom: -0.5rem;
  }
}
</style>
