import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import { i18n } from '@/i18n';
import DttUiKit from '@dtt/ui-kit';

Vue.config.productionTip = false;

import 'normalize.css';
import '@/assets/styles/index.scss';
import stores from '@/stores';

Vue.use(DttUiKit, { i18n: (key: any, value: any) => i18n.t(key, value) });

Vue.mixin({
  data: () => ({
    stores,
  }),
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
