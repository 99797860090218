import { DashboardStore } from '@/stores/domain/summary/DashboardStore';
import { Period } from '@/stores/core/Period';

export class SummaryStore {
  dashboard: DashboardStore;

  constructor(period: Period) {
    this.dashboard = new DashboardStore(period);
  }
}
