import {
  InfoStore,
  InfoViewStore,
} from '@/stores/domain/single-taxpayer/InfoStore';
import {
  InvoicesStore,
  InvoicesViewStore,
} from '@/stores/domain/single-taxpayer/InvoicesStore';
import { BankOperationsStore } from '@/stores/domain/single-taxpayer/BankOperationsStore';
import {
  ContractorListStore,
  ContractorListViewStore,
} from '@/stores/domain/single-taxpayer/ContractorListStore';
import { Period } from '@/stores/core/Period';
import {
  ReceiptStore,
  ReceiptViewStore,
} from '@/stores/domain/single-taxpayer/ReceiptStore';

export class SingleTaxpayerStore {
  info: InfoStore;
  infoView: InfoViewStore;

  invoices: InvoicesStore;
  invoicesView: InvoicesViewStore;
  receipt: ReceiptStore;
  receiptView: ReceiptViewStore;
  bankOperations: BankOperationsStore;
  contractors: ContractorListStore;
  contractorView: ContractorListViewStore;

  constructor(period: Period) {
    this.info = new InfoStore(period);
    this.infoView = new InfoViewStore(this.info);

    this.invoices = new InvoicesStore(period);
    this.invoicesView = new InvoicesViewStore(this.invoices);
    this.receipt = new ReceiptStore(period);
    this.receiptView = new ReceiptViewStore(this.receipt);
    this.bankOperations = new BankOperationsStore(period);
    this.contractors = new ContractorListStore(period);
    this.contractorView = new ContractorListViewStore(this.contractors);
  }
}
