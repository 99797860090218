<template>
  <div :class="$style.wrapper">
    <div :class="['card', $style.infoWrapper]" :key="$i18n.locale">
      <dtt-title tag="h2">{{ $t('page_summary.title') }}</dtt-title>
      <danger-toast :days="dashboard.periodEndDays" />
      <value-wrap>
        <small-grid :items="dashboard.commonView.budget" :cols="3" />
      </value-wrap>
      <outcome-vat-grid :items="dashboard.commonView.outcomeVat" />
      <income-vat-grid :items="dashboard.commonView.incomeVat" />
      <small-grid :items="dashboard.commonView.users" />
    </div>
    <div :class="['card', $style.chart]">
      <dtt-title tag="h3">{{ $t('page_summary.dynamic') }}</dtt-title>
      <div :class="$style.chart">
        <year-line-chart
          :data="dashboard.dynamicView"
          :labels="[$t('page_summary.dynamic')]"
          use-quarters
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ValueWrap from '@/components/ui/ValueWrap.vue';
import YearLineChart from '@/components/chart/YearLineChart.vue';
import SmallGrid from '@/components/domain/summary/SmallGrid.vue';
import IncomeVatGrid from '@/components/domain/summary/IncomeVatGrid.vue';
import OutcomeVatGrid from '@/components/domain/summary/OutcomeVatGrid.vue';
import DangerToast from '@/components/domain/summary/DangerToast.vue';
import { Observer } from 'mobx-vue';
import stores from '@/stores';

@Observer
@Component({
  components: {
    DangerToast,
    OutcomeVatGrid,
    IncomeVatGrid,
    SmallGrid,
    YearLineChart,
    ValueWrap,
  },
})
export default class SummaryView extends Vue {
  dashboard = stores.summary.dashboard;

  beforeMount() {
    this.dashboard.init();
    this.dashboard.fetch();
  }

  beforeDestroy() {
    this.dashboard.destroy();
  }
}
</script>

<style lang="scss" module>
.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.wrapper {
  padding-bottom: 1.6rem;
}

.chart {
  width: 100%;
  margin-top: 1.6rem;
  min-height: 280px;
}
</style>
