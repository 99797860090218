import {
  BankViolation,
  BankViolationView,
  TaxpayerViolation,
  TaxpayerViolationView,
} from '@/stores/domain/violations/types';
import { formatDate, handleNil } from '@/utils/format';
import { formatNumber } from '@/utils/numbers';
import {
  BankViolationType,
  BankViolationTypeLabel,
} from '@/stores/domain/violations/constants';
import { Option } from '@/types/option';
import { i18n } from '@/i18n';

export const formatBankViolationType = (
  raw: BankViolationType
): Option<BankViolationType> => {
  return {
    label: i18n.t(BankViolationTypeLabel[raw]) as string,
    value: raw,
  };
};

export const formatBankViolation = (raw: BankViolation): BankViolationView => {
  return {
    bankname: handleNil(raw.bankname),
    violationsByBank: formatBankViolationType(raw.violationsByBank),
    statementDate: handleNil(raw.statementDate, formatDate),
  };
};

export const formatTaxpayerViolation = (
  raw: TaxpayerViolation
): TaxpayerViolationView => {
  return {
    accountbalance: handleNil(raw.accountbalance, formatNumber),
    delta: handleNil(raw.delta, formatNumber),
    ndsbalanceCurrent: handleNil(raw.ndsbalanceCurrent, formatNumber),
    taxpayerName: handleNil(raw.taxpayerName),
    tin: handleNil(raw.tin),
  };
};
