import { ListStore } from '@/stores/domain/statistics/ListStore';
import { ListViewStore } from '@/stores/domain/statistics/ListViewStore';
import { ControlStore } from '@/stores/domain/statistics/Control';
import { ListVedStore } from '@/stores/domain/statistics/ListVedStore';
import { ListVedViewStore } from '@/stores/domain/statistics/ListVedViewStore';

export class StatisticsStore {
  list: ListStore;
  listView: ListViewStore;
  listVed: ListVedStore;
  listVedView: ListVedViewStore;
  control: ControlStore;

  constructor() {
    this.control = new ControlStore();
    this.list = new ListStore(this.control);
    this.listView = new ListViewStore(this.list);
    this.listVed = new ListVedStore(this.control);
    this.listVedView = new ListVedViewStore(this.listVed);
  }
}
