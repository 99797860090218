import { ListStore } from '@/stores/domain/taxpayers/ListStore';
import { makeAutoObservable } from 'mobx';
import { formatTaxpayer } from '@/stores/domain/taxpayers/helpers';

export class ListViewStore {
  private list: ListStore;

  constructor(list: ListStore) {
    makeAutoObservable(this);
    this.list = list;
  }

  get data() {
    return this.list.data.map(formatTaxpayer);
  }
}
