import { makeAutoObservable } from 'mobx';
import { SingleInvoice } from '@/stores/domain/invoices/types';
import invoiceApi from '@/api/domain/invoice';

export class SingleStore {
  isLoading = false;
  data: SingleInvoice | null = null;
  id: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  init(id: string) {
    this.id = id;
  }

  destroy() {
    this.data = null;
    this.isLoading = false;
    this.id = null;
  }

  async fetch() {
    if (!this.id) return;
    this.isLoading = true;
    try {
      const response = await invoiceApi.getOne(this.id);

      this.data = response.data[0];
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
