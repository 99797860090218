<template>
  <dtt-context-menu>
    <div :class="$style['lang-switch']">
      <div :class="$style.lang">
        <dtt-icon
          width="20px"
          height="20px"
          view-box="0 0 16 12"
          :name="selectedLocale.icon"
        />
      </div>
      <dtt-icon name="arrow-down" height="2rem" width="2rem" />
    </div>
    <template #content="{ toggle }">
      <div :class="$style['locale-wrapper']">
        <div
          v-for="loc in locales"
          :class="[$style.locale, loc.value === locale && $style.active]"
          :key="loc.value"
          @click="
            handleClick(loc);
            toggle();
          "
        >
          <dtt-icon
            width="20px"
            height="20px"
            view-box="0 0 16 12"
            :name="loc.icon"
          />
        </div>
      </div>
    </template>
  </dtt-context-menu>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { setI18nLanguage } from '@/i18n';

@Component
export default class LocaleSwitch extends Vue {
  locale = 'ru';

  locales = [
    {
      icon: 'country-russia',
      value: 'ru',
    },
    {
      icon: 'country-uk',
      value: 'en',
    },
  ];

  get selectedLocale() {
    return this.locales.find((l) => l.value === this.locale);
  }

  handleClick(locale: (typeof this.locales)[0]) {
    this.locale = locale.value;
    setI18nLanguage(this.locale);
  }

  beforeMount() {
    this.locale = this.$i18n.locale;
  }
}
</script>

<style lang="scss" module>
.lang-switch {
  display: flex;
  align-items: center;

  & > * {
    flex: 0 0 auto;
  }
}

.lang {
  position: relative;
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  border: 1px solid $--color-neutral-400;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.4rem;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 2rem;
  }
}

.locale-wrapper {
  width: 6rem;
  height: 100%;
  background-color: $--color-neutral-200;
}

.locale {
  padding: 0.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &:hover,
  &.active {
    background-color: $--color-primary;
  }
}
</style>
