import { baseApi } from '@/api/base';
import { toQuery } from '@/utils/query';
import { PageableResponse } from '@/types/pagination';
import {
  BankViolation,
  TaxpayerViolation,
} from '@/stores/domain/violations/types';

const prefix = '/commons/no/violations';

const violationApi = {
  getTaxpayers: (params: any) =>
    baseApi.get<PageableResponse<TaxpayerViolation>>(
      `${prefix}/user${toQuery(params)}`
    ),
  getBanks: (params: any) =>
    baseApi.get<PageableResponse<BankViolation>>(
      `${prefix}/banks${toQuery(params)}`
    ),
};

export default violationApi;
