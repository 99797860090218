import { ListStore } from '@/stores/domain/receipts/ListStore';
import { ReceiptViewStore } from '@/stores/domain/receipts/ListViewStore';
import { Period } from '@/stores/core/Period';

export class ReceiptsStore {
  period: Period;

  list: ListStore;
  listView: ReceiptViewStore;

  constructor(period: Period) {
    this.period = period;

    this.list = new ListStore(this.period);
    this.listView = new ReceiptViewStore(this.list);
  }
}
