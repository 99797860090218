<template>
  <div>Index View</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class IndexView extends Vue {}
</script>
