import {
  SaldoStatistics,
  SaldoStatisticsView,
  SaldoVedStatistics,
  SaldoVedStatisticsView,
} from './types';
import { handleNil } from '@/utils/format';
import { formatNumber } from '@/utils/numbers';

export const formatSaldoStatistic = (
  raw: SaldoStatistics
): SaldoStatisticsView => {
  return {
    currentPeriodVatBalance: handleNil(
      raw.currentPeriodVatBalance,
      formatNumber
    ),
    incomingVatCurrentPeriod: handleNil(
      raw.incomingVatCurrentPeriod,
      formatNumber
    ),
    incomingVatPreviousPeriod: handleNil(
      raw.incomingVatPreviousPeriod,
      formatNumber
    ),
    outgoingVatCurrentPeriod: handleNil(
      raw.outgoingVatCurrentPeriod,
      formatNumber
    ),
    outgoingVatPreviousPeriod: handleNil(
      raw.outgoingVatPreviousPeriod,
      formatNumber
    ),
    previousPeriodVatBalance: handleNil(
      raw.previousPeriodVatBalance,
      formatNumber
    ),
    taxpayerId: handleNil(raw.taxpayerId),
    taxpayerName: handleNil(raw.taxpayerName),
    trend:
      raw?.currentPeriodVatBalance !== undefined &&
      raw?.previousPeriodVatBalance !== undefined
        ? raw.currentPeriodVatBalance - raw.previousPeriodVatBalance > 0
        : null,
  };
};

export const formatSaldoVedStatistic = (
  raw: SaldoVedStatistics
): SaldoVedStatisticsView => {
  return {
    category: handleNil(raw.category),
    ndsbalance: handleNil(raw.ndsbalance, formatNumber),
    ndsbalanceOld: handleNil(raw.ndsbalanceOld, formatNumber),
    ndspaidinvin: handleNil(raw.ndspaidinvin, formatNumber),
    ndspaidinvinOld: handleNil(raw.ndspaidinvinOld, formatNumber),
    ndstotalout: handleNil(raw.ndstotalout, formatNumber),
    ndstotaloutOld: handleNil(raw.ndstotaloutOld, formatNumber),
    trend:
      raw?.ndsbalance !== undefined && raw?.ndsbalanceOld !== undefined
        ? raw.ndsbalance - raw.ndsbalanceOld > 0
        : null,
  };
};
