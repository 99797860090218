<template>
  <dtt-context-menu
    style="max-width: 220px"
    size="sm"
    v-model="modelValue"
    :options="options"
    use-value
  >
    <dtt-input
      name="quarter"
      readonly
      size="sm"
      :value="$t(label)"
      icon-right="arrow-down"
      :placeholder="$t('quarter.select')"
    />
  </dtt-context-menu>
</template>

<script lang="ts">
import { Component, ModelSync, Vue } from 'vue-property-decorator';
import { QUARTERS } from '@/constants/quarters';

@Component
export default class QuarterSelector extends Vue {
  @ModelSync('value', 'change', { type: Number, default: 1 })
  modelValue!: number;

  get label() {
    const current = QUARTERS.find((m) => m.value === this.modelValue);
    return current?.label || '';
  }

  get options() {
    return QUARTERS.map((option) => ({
      ...option,
      label: this.$t(option.label),
    }));
  }
}
</script>
