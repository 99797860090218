export const QUARTERS = [
  {
    label: 'quarter.first',
    value: 1,
  },
  {
    label: 'quarter.second',
    value: 2,
  },
  {
    label: 'quarter.third',
    value: 3,
  },
  {
    label: 'quarter.fourth',
    value: 4,
  },
];
