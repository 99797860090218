export const ReceiptStatisticTypes = [
  'OPERATION_BUY',
  'OPERATION_BUY_RETURN',
  'OPERATION_SELL',
  'OPERATION_SELL_RETURN',
] as const;

export const BankAccountTypes = ['SBER', 'CURRENT', 'ESCROW'] as const;

export type ReceiptStatisticType = (typeof ReceiptStatisticTypes)[number];
export type BankAccountType = (typeof BankAccountTypes)[number];

export const ReceiptStatisticTypeLabel: Record<ReceiptStatisticType, string> =
  ReceiptStatisticTypes.reduce((labels, l) => {
    labels[l] = `terms.receipt.operation_type.${l}`;
    return labels;
  }, {} as Record<ReceiptStatisticType, string>);
