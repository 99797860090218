import { makeAutoObservable } from 'mobx';
import { SingleStore } from '@/stores/domain/invoices/Single';
import { i18n } from '@/i18n';
import {
  formatInvoiceFee,
  formatSingleInvoice,
} from '@/stores/domain/invoices/helpers';

export class SingleView {
  private single: SingleStore;

  constructor(single: SingleStore) {
    makeAutoObservable(this);
    this.single = single;
  }

  get data() {
    if (!this.single.data) return null;
    return formatSingleInvoice(this.single.data);
  }

  get fees() {
    if (!this.single.data) return [];
    return this.single.data.items.map(formatInvoiceFee);
  }

  get tabs() {
    if (!this.single.data) return [];
    return [
      {
        label: i18n.t('page_single_invoice.tabs.main'),
        to: `/app/invoices/${this.single.data.documentId}`,
      },
      {
        label: i18n.t('page_single_invoice.tabs.fees'),
        to: `/app/invoices/${this.single.data.documentId}/fees`,
      },
    ];
  }

  get statements() {
    if (!this.data) return [];
    return [
      {
        label: 'page_invoices.table.doc_type',
        value: this.data.docType.label,
      },
      {
        label: 'page_invoices.table.serial_number',
        value: this.data.invoiceNumber,
      },
      {
        label: 'page_invoices.table.date',
        value: this.data.date,
      },
      {
        label: 'page_invoices.table.turnover_date',
        value: this.data.invoiceTurnoverDate,
      },
      {
        label: 'page_invoices.table.id',
        value: this.data.invoiceRegNum,
      },
      {
        label: 'page_invoices.table.parent_number',
        value: this.data.relatedInvoiceRegNum,
      },
      {
        label: 'page_invoices.table.contract_num',
        value: this.data.contractNum,
      },
      {
        label: 'page_invoices.table.contract_date',
        value: this.data.contractDate,
      },
      {
        label: 'page_invoices.table.seller_name',
        value: this.data.sellerName,
      },
      {
        label: 'page_invoices.table.seller_tin',
        value: this.data.sellerId,
      },
      {
        label: 'page_invoices.table.customer_name',
        value: this.data.buyerName,
      },
      {
        label: 'page_invoices.table.customer_tin',
        value: this.data.buyerId,
      },
      {
        label: 'page_invoices.table.sum_without_taxes',
        value: this.data.costExcludingVAT,
      },
      {
        label: 'page_invoices.table.excise',
        value: this.data.invoiceExcise,
      },
      {
        label: 'page_invoices.table.turnover_size',
        value: this.data.invoiceTurnoverSize,
      },
      {
        label: 'page_invoices.table.tax_sum',
        value: this.data.vatAmount,
      },
      {
        label: 'page_invoices.table.sum',
        value: this.data.costIncludingVAT,
      },
      {
        label: 'page_single_invoice.list.currency',
        value: this.data.currency,
      },
    ];
  }
}
