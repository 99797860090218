import { makeAutoObservable, reaction } from 'mobx';
import { Bank } from '@/stores/domain/banks/types';
import bankApi from '@/api/domain/bank';
import { Filter } from '@/stores/core/Filter';
import { Period } from '@/stores/core/Period';

export class ListStore {
  data: Bank[] = [];
  maxPage = 0;
  isLoading = false;

  filter = new Filter<{ search: string; page: number; size: number }>(
    { search: '', page: 0, size: 20 },
    { debounced: ['search'] }
  );
  private filterDisposer?: () => void;
  private period: Period;
  private periodDisposer?: () => void;

  constructor(period: Period) {
    makeAutoObservable(this);
    this.period = period;
  }

  init() {
    this.filterDisposer = this.filter.watch(() => this.fetch());
    this.periodDisposer = reaction(
      () => this.period.asParams,
      () => {
        this.filter.setSilent({ page: 0 });
        this.fetch();
      }
    );
  }

  destroy() {
    this.filterDisposer && this.filterDisposer();
    this.data = [];
    this.isLoading = false;
    this.filter.reset();
  }

  async fetch() {
    this.isLoading = true;
    try {
      const response = await bankApi.getList(this.getParams());

      this.maxPage = response.data.totalPages - 1;
      this.data = response.data.content;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  private getParams() {
    const { search } = this.filter.value;
    return {
      ...this.period.asParams,
      ...(search && { search }),
    };
  }
}
