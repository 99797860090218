import { ListStore } from '@/stores/domain/invoices/ListStore';
import { ListViewStore } from '@/stores/domain/invoices/ListViewStore';
import { Period } from '@/stores/core/Period';
import { SingleStore } from '@/stores/domain/invoices/Single';
import { SingleView } from '@/stores/domain/invoices/SingleView';

export class InvoicesStore {
  list: ListStore;
  listView: ListViewStore;
  single: SingleStore;
  singleView: SingleView;

  constructor(period: Period) {
    this.list = new ListStore(period);
    this.listView = new ListViewStore(this.list);
    this.single = new SingleStore();
    this.singleView = new SingleView(this.single);
  }
}
