import { makeAutoObservable } from 'mobx';
import { SaldoVedStatisticsView } from './types';
import { formatSaldoVedStatistic } from '@/stores/domain/statistics/helpers';
import { ListVedStore } from '@/stores/domain/statistics/ListVedStore';

export class ListVedViewStore {
  private list: ListVedStore;

  constructor(list: ListVedStore) {
    makeAutoObservable(this);
    this.list = list;
  }

  get data(): SaldoVedStatisticsView[] {
    return this.list.control.filter.isValid
      ? this.list.data?.content?.map(formatSaldoVedStatistic) || []
      : [];
  }
}
