import { RouteConfig } from 'vue-router';

const View = () => import('@/views/app/banks.vue');
const Index = () => import('@/views/app/banks/index.vue');

const banksRoutes: Array<RouteConfig> = [
  {
    path: 'banks',
    component: View,
    children: [
      {
        path: '',
        name: 'banks-index',
        component: Index,
      },
    ],
  },
];

export default banksRoutes;
