import { ListStore } from '@/stores/domain/statistics/ListStore';
import { makeAutoObservable } from 'mobx';
import { SaldoStatisticsView } from './types';
import { formatSaldoStatistic } from '@/stores/domain/statistics/helpers';

export class ListViewStore {
  private list: ListStore;

  constructor(list: ListStore) {
    makeAutoObservable(this);
    this.list = list;
  }

  get data(): SaldoStatisticsView[] {
    return this.list.control.filter.isValid
      ? this.list.data?.content?.map(formatSaldoStatistic) || []
      : [];
  }
}
