<template>
  <div class="card card--sm card--border" :class="$style.wrapper">
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ValueWrap extends Vue {}
</script>

<style lang="scss" module>
.wrapper {
  background-color: $--color-white;
}
</style>
