import { BankListStore } from '@/stores/domain/violations/BankListStore';
import { makeAutoObservable } from 'mobx';
import { formatBankViolation } from '@/stores/domain/violations/helpers';

export class BankListViewStore {
  private list: BankListStore;

  constructor(list: BankListStore) {
    makeAutoObservable(this);
    this.list = list;
  }

  get data() {
    return this.list.data.map(formatBankViolation);
  }
}
