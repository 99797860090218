import { makeAutoObservable } from 'mobx';
import { YearQuarterParams } from '@/types/params';

export class Period {
  year: number;
  quarter: number | null;

  constructor() {
    makeAutoObservable(this);
    const now = new Date();
    const lsYear = localStorage.getItem('year');
    const lsQuarter = localStorage.getItem('quarter');
    this.year = lsYear ? +lsYear : now.getFullYear();
    this.quarter = lsQuarter ? +lsQuarter : Math.ceil((now.getMonth() + 1) / 3);
  }

  get asDate(): Date {
    if (!this.quarter) return new Date(this.year);
    return new Date(this.year, (this.quarter - 1) * 3);
  }

  get month(): number {
    if (!this.quarter) return 0;
    return (this.quarter - 1) * 3;
  }

  get asParams(): YearQuarterParams {
    return {
      year: this.year,
      ...(this.quarter && { quarter: this.quarter }),
    };
  }

  setYear(year: number) {
    this.year = year;
    localStorage.setItem('year', year.toString());
  }

  setQuarter(quarter: number | null) {
    this.quarter = quarter;
    if (quarter) localStorage.setItem('quarter', quarter.toString());
    else localStorage.removeItem('quarter');
  }
}
