import { RouteConfig } from 'vue-router';

const InvoicesView = () => import('@/views/app/invoices.vue');
const InvoicesIndexView = () => import('@/views/app/invoices/index.vue');
const InvoicesIdView = () => import('@/views/app/invoices/_id.vue');
const InvoicesIdIndexView = () => import('@/views/app/invoices/_id/index.vue');
const InvoicesIdFeesView = () => import('@/views/app/invoices/_id/fees.vue');

const invoicesRoutes: Array<RouteConfig> = [
  {
    path: 'invoices',
    component: InvoicesView,
    children: [
      {
        path: '',
        name: 'invoices-index',
        component: InvoicesIndexView,
      },
      {
        path: ':id',
        component: InvoicesIdView,
        children: [
          {
            path: '',
            name: 'invoices-id-index',
            meta: { basePath: '/app/invoices' },
            component: InvoicesIdIndexView,
          },
          {
            path: 'fees',
            name: 'invoices-id-fees',
            meta: { basePath: '/app/invoices' },
            component: InvoicesIdFeesView,
          },
        ],
      },
    ],
  },
];

export default invoicesRoutes;
