import { observable, reaction } from 'mobx';
import {
  formatBankOperation,
  TaxOfficerBankOperationView,
} from '@/stores/domain/bank-operations/helpers';
import { Filter } from '@/stores/core/Filter';
import { Period } from '@/stores/core/Period';
import bankOperationsApi from '@/api/domain/bankOperations';
import { PageableParams, SortParams } from '@/types/params';
import { TaxOfficerBankOperation } from '@/stores/domain/bank-operations/types';

export type Sort<T> = {
  prop: keyof T | '';
  direction: 'asc' | 'desc' | null;
};

interface FilterState<T> {
  sendler: string;
  receiver: string;
  page: number;
  size: number;
  sort: Sort<T>;
}

export class ListStore<
  T extends TaxOfficerBankOperation = TaxOfficerBankOperation
> {
  data: T[] = [];
  isLoading = false;
  maxPage = 0;
  filters = new Filter<FilterState<T>>(
    {
      receiver: '',
      sendler: '',
      page: 0,
      size: 20,
      sort: {
        prop: 'operationDate',
        direction: 'desc',
      },
    },
    { debounced: ['receiver', 'sendler'] }
  );
  private period: Period;
  private filterDisposer: (() => void) | null = null;
  private periodDisposer: (() => void) | null = null;

  constructor(period: Period) {
    observable(this);
    this.period = period;
  }

  get dataView(): TaxOfficerBankOperationView[] {
    return this.filters.isValid ? this.data.map(formatBankOperation) : [];
  }

  init() {
    this.filterDisposer = this.filters.watch(() => this.fetch());
    this.periodDisposer = reaction(
      () => this.period.asParams,
      () => {
        this.filters.setSilent({ page: 0 });
        this.fetch();
      }
    );
  }

  destroy() {
    this.filterDisposer && this.filterDisposer();
    this.periodDisposer && this.periodDisposer();
    this.isLoading = false;
    this.data = [];
    this.filters.reset();
  }

  async fetch() {
    this.isLoading = true;
    try {
      const response = await bankOperationsApi.getListTaxOfficer<T>(
        this.getParams()
      );

      this.maxPage = response.data.totalPages - 1;
      this.data = response.data.content;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  private getParams(): PageableParams {
    const { sort, size, page, ...queryParams } = this.filters.value;
    const pageParams = { pageNum: page, pageSize: size };
    const params = Object.assign(queryParams, pageParams, this.period.asParams);
    const sorts = {
      ...(sort.prop && {
        columnSort: sort.prop,
        order: sort.direction?.toUpperCase(),
      }),
    } as SortParams;

    return Object.assign(params, sorts);
  }
}
