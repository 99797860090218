import { ListStore } from '@/stores/domain/taxpayers/ListStore';
import { ListViewStore } from '@/stores/domain/taxpayers/ListViewStore';
import { Period } from '@/stores/core/Period';

export class TaxpayersStore {
  list: ListStore;
  listView: ListViewStore;

  constructor(period: Period) {
    this.list = new ListStore(period);
    this.listView = new ListViewStore(this.list);
  }
}
