export const MENU = [
  {
    label: 'menu.main',
    to: '/app/summary',
    icon: 'summary',
  },
  {
    label: 'menu.statistics',
    to: '/app/statistics',
    icon: 'tax-gap',
  },
  {
    label: 'menu.taxpayers',
    to: '/app/taxpayers',
    icon: 'individuals',
  },
  {
    label: 'menu.invoices',
    to: '/app/invoices',
    icon: 'reference-books',
  },
  {
    label: 'menu.banks',
    to: '/app/banks',
    icon: 'main',
  },
  {
    label: 'menu.bank-operations',
    to: '/app/bank-operations',
    icon: 'a-ticket',
  },
  {
    label: 'menu.receipts',
    to: '/app/receipts',
    icon: 'reports',
  },
  {
    label: 'menu.violations',
    to: '/app/violations',
    icon: 'attention',
  },
];
