import { RouteConfig } from 'vue-router';

const View = () => import('@/views/app/taxpayers.vue');
const IndexView = () => import('@/views/app/taxpayers/index.vue');
const IdView = () => import('@/views/app/taxpayers/_id.vue');
const IdIndexView = () => import('@/views/app/taxpayers/_id/index.vue');
const IdInvoicesView = () => import('@/views/app/taxpayers/_id/invoices.vue');
const IdReceiptsView = () => import('@/views/app/taxpayers/_id/receipts.vue');
const IdReceiptsIndexView = () =>
  import('@/views/app/taxpayers/_id/receipts/index.vue');
const IdBankOperationsView = () =>
  import('@/views/app/taxpayers/_id/bank-operations.vue');
const IdContractorsView = () =>
  import('@/views/app/taxpayers/_id/contractors.vue');

const taxpayersRoutes: Array<RouteConfig> = [
  {
    path: 'taxpayers',
    component: View,
    children: [
      {
        path: '',
        name: 'taxpayers-index',
        component: IndexView,
      },
      {
        path: ':id',
        component: IdView,
        children: [
          {
            path: '',
            name: 'taxpayers-id-index',
            component: IdIndexView,
          },
          {
            path: 'invoices',
            name: 'taxpayers-id-invoices',
            component: IdInvoicesView,
          },
          {
            path: 'receipts',
            component: IdReceiptsView,
            children: [
              {
                path: '',
                name: 'taxpayers-id-receipts-index',
                component: IdReceiptsIndexView,
              },
            ],
          },
          {
            path: 'bank-operations',
            name: 'taxpayers-id-bank_operations',
            component: IdBankOperationsView,
          },
          {
            path: 'contractors',
            name: 'taxpayers-id-contractors',
            component: IdContractorsView,
          },
        ],
      },
    ],
  },
];

export default taxpayersRoutes;
