import { TaxOfficerBankOperation } from '@/stores/domain/bank-operations/types';
import { formatDate, handleNil } from '@/utils/format';
import { formatNumber } from '@/utils/numbers';

export type TaxOfficerBankOperationView = ReturnType<
  typeof formatBankOperation
>;

export const formatBankOperation = (operation: TaxOfficerBankOperation) => ({
  operationDate: handleNil(operation.operationDate, formatDate),
  senderTin: handleNil(operation.payeeInn),
  senderName: handleNil(operation.payeeName),
  senderBank: handleNil(operation.payeeBankName),
  receiverTin: handleNil(operation.payerInn),
  receiverName: handleNil(operation.payerName),
  receiverBank: handleNil(operation.payerBankName),
  purpose: operation.docPurpose,
  sum: handleNil(operation.docSum, formatNumber),
  sumVat: handleNil(operation.vatAmount, formatNumber),
});
