import { makeAutoObservable } from 'mobx';
import { SaldoStatistics } from './types';
import { PageableResponse } from '@/types/pagination';
import statisticsApi from '@/api/domain/statistics';
import { ControlStore } from '@/stores/domain/statistics/Control';

export class ListStore {
  data: PageableResponse<SaldoStatistics> | null = null;
  isLoading = false;
  maxPage = 0;

  readonly control: ControlStore;
  private controlDisposer: (() => void) | null = null;

  constructor(control: ControlStore) {
    makeAutoObservable(this);
    this.control = control;
  }

  init() {
    this.control.reset();
    this.controlDisposer = this.control.filter.watch(() => this.fetch());
    this.control.filter.setSilent({
      sort: { prop: 'currentPeriodVatBalance', direction: 'desc' },
    });
  }

  destroy() {
    this.controlDisposer && this.controlDisposer();
    this.isLoading = false;
    this.data = null;
    this.control.reset();
  }

  async fetch() {
    this.isLoading = true;
    try {
      const params = this.control.getParams();
      const response = await statisticsApi.getSaldo(params);

      this.maxPage = response.data.totalPages - 1;
      this.data = response.data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
