import moment from 'moment';

export const handleNil = (
  val: any,
  format?: (val: any) => any,
  defaultValue: any = '—'
): any => {
  if (val === null || val === undefined) return defaultValue;
  return format ? format(val) : val;
};

export const formatDate = (val: string, format = 'DD.MM.yy') => {
  return moment(val).format(format);
};
