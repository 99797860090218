<template>
  <dtt-layout :aside-items="MENU">
    <template #logo>
      <img src="@/assets/img/logo-sm.svg" alt="DTT logo" />
    </template>
    <template #header> <the-header /> </template>
    <router-view />
  </dtt-layout>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { MENU } from '@/constants/menu';
import TheHeader from '@/components/TheHeader.vue';

@Component({
  components: { TheHeader },
})
export default class AppView extends Vue {
  get MENU() {
    return MENU.map((item) => ({ ...item, label: this.$t(item.label) }));
  }

  @Watch('$i18n.locale')
  onLocaleChange() {
    location.reload();
  }
}
</script>

<style lang="scss" module></style>
