import { configure } from 'mobx';
import { InvoicesStore } from '@/stores/domain/invoices';
import { SummaryStore } from '@/stores/domain/summary';
import { StatisticsStore } from '@/stores/domain/statistics';
import { ReceiptsStore } from '@/stores/domain/receipts';
import { BankOperationsStore } from '@/stores/domain/bank-operations';
import { TaxpayersStore } from '@/stores/domain/taxpayers';
import { SingleTaxpayerStore } from '@/stores/domain/single-taxpayer';
import { ViolationsStore } from '@/stores/domain/violations';
import { BanksStore } from '@/stores/domain/banks';
import { Period } from '@/stores/core/Period';

configure({
  useProxies: 'ifavailable',
});

export class Stores {
  period: Period;

  summary: SummaryStore;
  statistics: StatisticsStore;
  invoices: InvoicesStore;
  receipts: ReceiptsStore;
  bankOperations: BankOperationsStore;
  taxpayers: TaxpayersStore;
  singleTaxpayer: SingleTaxpayerStore;
  violations: ViolationsStore;
  banks: BanksStore;

  constructor() {
    this.period = new Period();

    this.summary = new SummaryStore(this.period);
    this.statistics = new StatisticsStore();
    this.invoices = new InvoicesStore(this.period);
    this.receipts = new ReceiptsStore(this.period);
    this.bankOperations = new BankOperationsStore(this.period);
    this.taxpayers = new TaxpayersStore(this.period);
    this.singleTaxpayer = new SingleTaxpayerStore(this.period);
    this.violations = new ViolationsStore(this.period);
    this.banks = new BanksStore(this.period);
  }
}

const stores = new Stores();

export default stores;
