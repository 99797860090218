import axios from 'axios';

const baseApi = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://ui-api.dev-24x7.com/ui/api/v1'
      : '/api',
});

export { baseApi };
