import { ListStore } from '@/stores/domain/bank-operations/ListStore';
import { Period } from '@/stores/core/Period';

export class BankOperationsStore {
  list: ListStore;

  constructor(period: Period) {
    this.list = new ListStore(period);
  }
}
