import { ListStore } from '@/stores/domain/invoices/ListStore';
import { makeAutoObservable } from 'mobx';
import { formatInvoice } from '@/stores/domain/invoices/helpers';

export class ListViewStore {
  private list: ListStore;

  constructor(list: ListStore) {
    makeAutoObservable(this);
    this.list = list;
  }

  get data() {
    return this.list.filter.isValid
      ? this.list.data.map((item) => formatInvoice(item))
      : [];
  }
}
