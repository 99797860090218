import {
  Contractor,
  ContractorView,
  ReceiptsInfo,
  ReceiptsInfoView,
  ReceiptStatistic,
  ReceiptStatisticView,
  TaxpayerInfo,
  TaxpayerInfoView,
} from '@/stores/domain/single-taxpayer/types';
import { handleNil, formatDate } from '@/utils/format';
import { formatNumber } from '@/utils/numbers';
import {
  formatTaxpayerActivity,
  formatTaxpayerEscrow,
} from '@/stores/domain/taxpayers/helpers';
import { TaxpayerBankOperation } from '@/stores/domain/bank-operations/types';
import { parseArrayLikeString } from '@/utils/string';
import { i18n } from '@/i18n';
import {
  ReceiptStatisticType,
  ReceiptStatisticTypeLabel,
} from '@/stores/domain/single-taxpayer/constants';
import { Option } from '@/types/option';

export const formatTaxpayerInfo = (raw: TaxpayerInfo): TaxpayerInfoView => {
  return {
    tin: handleNil(raw.tin),
    nameRu: handleNil(raw.nameRu),
    accountValue: handleNil(raw.accountValue),
    accountbalance: handleNil(raw.accountbalance, formatNumber),
    errorKkmRisk: raw.errorKkmRisk,
    escrowAccountType: formatTaxpayerEscrow(raw.escrowAccountType),
    status: formatTaxpayerActivity(raw.status),
    ndsbalance: handleNil(raw.ndsbalance, formatNumber),
    ndsinvin: handleNil(raw.ndsinvin, formatNumber),
    ndsinvout: handleNil(raw.ndsinvout, formatNumber),
    ndsnotpaidinvin: handleNil(raw.ndsnotpaidinvin, formatNumber),
    ndspaidinvin: handleNil(raw.ndspaidinvin, formatNumber),
    ndsreceiptout: handleNil(raw.ndsreceiptout, formatNumber),
    ndsstatwoinvin: handleNil(raw.ndsstatwoinvin, formatNumber),
    ndstotalout: handleNil(raw.ndstotalout, formatNumber),
    saldoNdsRisk: raw.saldoNdsRisk,
    stopOperationRisk: raw.stopOperationRisk,
  };
};

export type TaxpayerBankOperationView = ReturnType<typeof formatBankOperation>;

export const formatBankOperation = (operation: TaxpayerBankOperation) => ({
  ...operation,
  accountType: i18n.t(`page_bank.accountTypes.${operation.accountType}`),
  date: handleNil(operation.operationDate, formatDate),
  docKind: i18n.t(`page_bank.purposeTypes.${operation.docKind}`),
  docPurpose: operation.docPurpose,
  contractorInn: handleNil(operation.payerInn || operation.payeeInn),
  contractorName: operation.payerName || operation.payeeName,
  paymentAmount: handleNil(operation.paymentAmount, formatNumber),
  withdrawal: handleNil(operation.withdrawal, formatNumber),
  vatAmount: handleNil(operation.vatAmount, formatNumber),
  invoiceNum: parseArrayLikeString(operation.invoiceNum),
  invoiceId: parseArrayLikeString(operation.invoiceId),
});

export const formatContractor = (raw: Contractor): ContractorView => {
  return {
    counterpartyInn: handleNil(raw.counterpartyInn),
    counterpartyName: handleNil(raw.counterpartyName),
    invoiceCount: formatNumber(raw.invoiceCount, { fractionDigits: 0 }),
    operationCountWithoutInvoice: formatNumber(
      raw.operationCountWithoutInvoice,
      { fractionDigits: 0 }
    ),
    totalCostIncludingVAT: handleNil(raw.totalCostIncludingVAT, formatNumber),
    totalCostIncludingVATForUnpaid: handleNil(
      raw.totalCostIncludingVATForUnpaid,
      formatNumber
    ),
    totalVAT: handleNil(raw.totalVAT, formatNumber),
    totalVATFromPaidInvoices: handleNil(
      raw.totalVATFromPaidInvoices,
      formatNumber
    ),
    totalVATFromUnPaidInvoices: handleNil(
      raw.totalVATFromUnPaidInvoices,
      formatNumber
    ),
    totalVATOperationWithoutInvoices: handleNil(
      raw.totalVATWithoutInvoices,
      formatNumber
    ),
    totalVATWithoutInvoices: handleNil(
      raw.totalVATWithoutInvoices,
      formatNumber
    ),
    unPaidInvoiceCount: formatNumber(raw.unPaidInvoiceCount, {
      fractionDigits: 0,
    }),
  };
};

export const formatReceiptsInfo = (raw: ReceiptsInfo): ReceiptsInfoView => {
  const format = (n: number) => handleNil(n, formatNumber);
  return {
    acquiringCash: format(raw.acquiringCash),
    revenueCash: format(raw.revenueCash),
    revenueNonCash: format(raw.revenueNonCash),
    revenueTotal: format(raw.revenueTotal),
    totalReceipts: formatNumber(raw.totalReceipts, { fractionDigits: 0 }),
    vatAcquiring: format(raw.vatAcquiring),
    vatCash: format(raw.vatCash),
    vatNonCash: format(raw.vatNonCash),
    vatTotal: format(raw.vatTotal),
  };
};

export const formatReceiptStatisticType = (
  raw: ReceiptStatisticType
): Option<ReceiptStatisticType> => {
  return {
    label: i18n.t(ReceiptStatisticTypeLabel[raw]) as string,
    value: raw,
  };
};

export const formatReceiptStatistic = (
  raw: ReceiptStatistic
): ReceiptStatisticView => {
  const format = (n: number) => handleNil(n, formatNumber);
  return {
    acquiringCash: format(raw.acquiringCash),
    operationType: formatReceiptStatisticType(raw.operationType),
    revenueCash: format(raw.revenueCash),
    revenueNonCash: format(raw.revenueNonCash),
    revenueTotal: format(raw.revenueTotal),
    totalReceipts: formatNumber(raw.totalReceipts, { fractionDigits: 0 }),
    vatAcquiring: format(raw.vatAcquiring),
    vatCash: format(raw.vatCash),
    vatNonCash: format(raw.vatNonCash),
    vatTotal: format(raw.vatTotal),
  };
};
