import { Period } from '@/stores/core/Period';
import { makeAutoObservable, reaction } from 'mobx';
import {
  ReceiptsInfo,
  ReceiptStatistic,
} from '@/stores/domain/single-taxpayer/types';
import {
  formatReceiptsInfo,
  formatReceiptStatistic,
} from '@/stores/domain/single-taxpayer/helpers';
import taxpayerApi from '@/api/domain/taxpayer';
import { i18n } from '@/i18n';

export class ReceiptStore {
  info: ReceiptsInfo | null = null;
  statistics: ReceiptStatistic[] = [];
  isLoading = false;
  tin: string | null = null;

  private periodDisposer?: () => void;

  constructor(private readonly period: Period) {
    makeAutoObservable(this);
  }

  get revenueDelta() {
    if (!this.info) return 0;
    return this.info.acquiringCash - this.info.revenueNonCash;
  }

  get vatDelta() {
    if (!this.info) return 0;
    return this.info.vatAcquiring - this.info.vatNonCash;
  }

  init(tin: string) {
    this.tin = tin;
    this.periodDisposer = reaction(
      () => this.period.asParams,
      () => this.fetch()
    );
  }

  destroy() {
    this.periodDisposer && this.periodDisposer();
    this.tin = null;
    this.info = null;
    this.statistics = [];
    this.isLoading = false;
  }

  async fetch() {
    this.isLoading = true;
    try {
      const [info, statistics] = await Promise.all([
        taxpayerApi.getReceiptsInfo(this.getParams()),
        taxpayerApi.getReceiptsStatistic(this.getParams()),
      ]);

      this.info = info.data;
      this.statistics = statistics.data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  private getParams() {
    return {
      ...this.period.asParams,
      tin: this.tin,
    };
  }
}

export class ReceiptViewStore {
  constructor(private readonly model: ReceiptStore) {
    makeAutoObservable(this);
  }

  get info() {
    if (!this.model.info) return null;
    return formatReceiptsInfo(this.model.info);
  }

  get statistics() {
    return this.model.statistics.map(formatReceiptStatistic);
  }

  get revenue() {
    if (!this.info) return null;
    const prefix = (path: string) =>
      i18n.t(`page_receipts.dashboard.revenue.${path}`);
    return {
      label: prefix('title'),
      data: [
        {
          header: prefix('sum'),
          value: this.info.revenueTotal,
          color: '#4F79D0',
        },
        {
          header: prefix('online_pay'),
          value: this.info.revenueNonCash,
          info: prefix('online_pay_info'),
          // delta: this.model.revenueDelta,
        },
        {
          header: prefix('offline_pay'),
          value: this.info.revenueCash,
        },
        {
          header: prefix('acquiring'),
          value: this.info.acquiringCash,
        },
      ],
    };
  }

  get vat() {
    if (!this.info) return null;
    const prefix = (path: string) =>
      i18n.t(`page_receipts.dashboard.vat.${path}`);
    return {
      label: prefix('title'),
      data: [
        {
          header: prefix('sum'),
          value: this.info.vatTotal,
          color: '#4F79D0',
        },
        {
          header: prefix('online_pay'),
          value: this.info.vatNonCash,
          info: prefix('online_pay_info'),
          // delta: this.model.vatDelta,
        },
        {
          header: prefix('offline_pay'),
          value: this.info.vatCash,
        },
        {
          header: prefix('acquiring'),
          value: this.info.vatAcquiring,
        },
      ],
    };
  }
}
