import { Bank, BankView } from '@/stores/domain/banks/types';
import { handleNil } from '@/utils/format';
import { formatNumber } from '@/utils/numbers';

export const formatBank = (bank: Bank): BankView => {
  return {
    bankname: handleNil(bank.bankname),
    clientstotal: handleNil(bank.clientstotal, (val) =>
      formatNumber(val, { fractionDigits: 0 })
    ),
    escrowaccounts: handleNil(bank?.escrowaccounts, (val) =>
      formatNumber(val, { fractionDigits: 0 })
    ),
    escrowbalance: handleNil(bank?.escrowbalance, formatNumber),
  };
};
