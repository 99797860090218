import {
  CommonSummaryView,
  DynamicSummaryView,
  CommonSummary,
  DynamicSummary,
} from './types';
import { handleNil } from '@/utils/format';
import { formatNumber } from '@/utils/numbers';
import { i18n } from '@/i18n';
import router from '@/router';

export const formatCommonSummary = (
  raw?: CommonSummary | null
): CommonSummaryView => {
  const prefix = 'page_summary.dashboard';
  const t = (value: string) => i18n.t(value) as string;
  const format = (val: any, fractionDigits = 2) =>
    handleNil(val, (v) => formatNumber(v, { fractionDigits }));

  return {
    budget: [
      {
        header: `${prefix}.budget`,
        value: format(raw?.currentVATToBudget),
        color: '#4F79D0',
        info: `${prefix}.budget_info`,
      },
      {
        header: `${prefix}.budget_min`,
        value: format(raw?.minimumVATToBudget),
        color: '#4F79D0',
        info: `${prefix}.budget_min_info`,
      },
      {
        header: `${prefix}.escrow`,
        value: format(raw?.totalAmountInEscrowAccounts),
        color: '#4F79D0',
        info: `${prefix}.escrow_info`,
        delta:
          (raw?.totalAmountInEscrowAccounts ?? 0) -
          (raw?.currentVATToBudget ?? 0),
      },
    ],
    outcomeVat: [
      {
        header: `${prefix}.outcome.total`,
        value: format(raw?.outgoingVATTotal),
        info: `${prefix}.outcome.total_info`,
      },
      {
        header: `${prefix}.outcome.invoice`,
        value: format(raw?.vatAmountFromInvoices),
        onClick: () => {
          router.push('/app/invoices');
        },
        info: `${prefix}.outcome.invoice_info`,
      },
      {
        header: `${prefix}.outcome.receipt`,
        value: format(raw?.vatAmountFromReceipts),
        onClick: () => {
          router.push('/app/receipts');
        },
        info: `${prefix}.outcome.receipt_info`,
      },
    ],
    incomeVat: [
      {
        header: `${prefix}.income.payed`,
        value: format(raw?.vatAmountFromPaidInvoices),
        onClick: () => {
          router.push({
            path: '/app/invoices',
            query: { paymentStatuses: 'PAID,PARTIALLY_PAID' },
          });
        },
        info: `${prefix}.income.payed_info`,
      },
      {
        header: `${prefix}.income.total`,
        value: format(raw?.incomingVATTotal),
        onClick: () => {
          router.push('/app/invoices');
        },
        info: `${prefix}.income.total_info`,
      },
      {
        header: `${prefix}.income.not_payed`,
        value: format(raw?.vatAmountFromUnpaidInvoices),
        onClick: () => {
          router.push({
            path: '/app/invoices',
            query: { paymentStatuses: 'NOT_PAID,PARTIALLY_PAID' },
          });
        },
        info: `${prefix}.income.not_payed_info`,
      },
    ],
    users: [
      {
        header: `${prefix}.users.total`,
        value: format(raw?.registeredTaxpayers, 0),
        info: t(`${prefix}.users.total_info`),
      },
      {
        header: `${prefix}.users.active`,
        value: format(raw?.activeTaxpayers, 0),
        color: '#4F79D0',
        onClick: () => {
          router.push('/app/taxpayers');
        },
        info: `${prefix}.users.active_info`,
      },
    ],
  };
};

export const formatDynamicSummary = (
  raw?: DynamicSummary | null
): DynamicSummaryView => {
  const {
    Q1 = null,
    Q2 = null,
    Q3 = null,
    Q4 = null,
  } = raw?.quarterlyVatBalance || {};
  return [Q1, Q2, Q3, Q4];
};
