import { Option } from '@/types/option';

export const TaxpayerActivities = ['ACTIVE', 'INACTIVE'] as const;
export const TaxpayerEscrows = ['MANDATORY', 'OPTIONAL'] as const;
export const TaxpayerRPROs = ['ACCEPTED', 'ABSENT'] as const;
export const TaxpayerRisks = ['RED', 'YELLOW', 'GREEN'] as const;

export type TaxpayerActivityType = (typeof TaxpayerActivities)[number];
export type TaxpayerEscrowType = (typeof TaxpayerEscrows)[number];
export type TaxpayerRPROType = (typeof TaxpayerRPROs)[number];
export type TaxpayerRiskType = (typeof TaxpayerRisks)[number];

export const TaxpayerActivityLabel = TaxpayerActivities.reduce((labels, l) => {
  labels[l] = `terms.taxpayer.activity.${l}`;
  return labels;
}, {} as Record<TaxpayerActivityType, string>);

export const TaxpayerActivityOptions: Option<TaxpayerActivityType>[] =
  TaxpayerActivities.map((value) => ({
    label: TaxpayerActivityLabel[value],
    value,
  }));

export const TaxpayerEscrowLabel = TaxpayerEscrows.reduce((labels, l) => {
  labels[l] = `terms.taxpayer.escrow.${l}`;
  return labels;
}, {} as Record<TaxpayerEscrowType, string>);

export const TaxpayerEscrowOptions: Option<TaxpayerEscrowType>[] =
  TaxpayerEscrows.map((value) => ({
    label: TaxpayerEscrowLabel[value],
    value,
  }));

export const TaxpayerRPROLabel = TaxpayerRPROs.reduce((labels, l) => {
  labels[l] = `terms.taxpayer.rpro.${l}`;
  return labels;
}, {} as Record<TaxpayerRPROType, string>);

export const TaxpayerRPROOptions: Option<TaxpayerRPROType>[] =
  TaxpayerRPROs.map((value) => ({
    label: TaxpayerRPROLabel[value],
    value,
  }));

export const TaxpayerRiskLabel = TaxpayerRisks.reduce((labels, l) => {
  labels[l] = `terms.taxpayer.risk.${l}`;
  return labels;
}, {} as Record<TaxpayerRiskType, string>);

export const TaxpayerRiskOptions: Option<TaxpayerRiskType>[] =
  TaxpayerRisks.map((value) => ({
    label: TaxpayerRiskLabel[value],
    value,
  }));

export const TaxpayerEscrowColor: Record<TaxpayerEscrowType, string> = {
  MANDATORY: 'danger',
  OPTIONAL: 'success',
};
export const TaxpayerRPROColor: Record<TaxpayerRPROType, string> = {
  ACCEPTED: 'danger',
  ABSENT: 'success',
};
export const TaxpayerRiskColor: Record<TaxpayerRiskType, string> = {
  RED: 'danger',
  GREEN: 'success',
  YELLOW: 'warning',
};
