import { baseApi } from '@/api/base';
import { Bank } from '@/stores/domain/banks/types';
import { toQuery } from '@/utils/query';
import { PageableResponse } from '@/types/pagination';

const prefix = '/commons/no';

const bankApi = {
  getList: (params: any) =>
    baseApi.get<PageableResponse<Bank>>(`${prefix}/bank${toQuery(params)}`),
};

export default bankApi;
