import { baseApi } from '@/api/base';
import { PageableResponse } from '@/types/pagination';
import { PageableParams } from '@/types/params';

const prefix = '/statements/no';

const bankOperationsApi = {
  getListTaxOfficer: <T>(params: PageableParams) =>
    baseApi.get<PageableResponse<T>>(`${prefix}/statement`, { params }),
  getListTaxpayer: <T>(params: PageableParams) =>
    baseApi.get<PageableResponse<T>>(`${prefix}/user/statement`, { params }),
};

export default bankOperationsApi;
