<template>
  <dtt-date-picker
    :value="date"
    @change="date = $event"
    style="max-width: 200px"
    fallback-mode="year"
  >
    <dtt-input
      name="year"
      readonly
      size="sm"
      :value="modelValue"
      icon-right="calendar"
      :placeholder="$t('year')"
    />
  </dtt-date-picker>
</template>

<script lang="ts">
import { Component, ModelSync, Vue } from 'vue-property-decorator';

@Component
export default class YearSelector extends Vue {
  @ModelSync('value', 'change', {
    type: Number,
    default: new Date().getFullYear(),
  })
  modelValue!: number;

  get date() {
    return new Date(this.modelValue, 0, 1);
  }

  set date(value: Date) {
    this.modelValue = value.getFullYear();
  }
}
</script>
