import { makeAutoObservable, reaction } from 'mobx';
import taxpayerApi from '@/api/domain/taxpayer';
import { Period } from '@/stores/core/Period';
import {
  ReceiptsInfo,
  ReceiptStatistic,
} from '@/stores/domain/single-taxpayer/types';

export class ListStore {
  info: ReceiptsInfo | null = null;
  statistics: ReceiptStatistic[] = [];
  isLoading = false;

  private periodDisposer?: () => void;

  constructor(private readonly period: Period) {
    makeAutoObservable(this);
  }

  init() {
    this.periodDisposer = reaction(
      () => this.period.asParams,
      () => this.fetch()
    );
  }

  destroy() {
    this.periodDisposer && this.periodDisposer();
    this.info = null;
    this.statistics = [];
    this.isLoading = false;
  }

  async fetch() {
    this.isLoading = true;
    try {
      const [info, statistics] = await Promise.all([
        taxpayerApi.getReceiptsInfo(this.getParams()),
        taxpayerApi.getReceiptsStatistic(this.getParams()),
      ]);

      this.info = info.data;
      this.statistics = statistics.data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  private getParams() {
    return this.period.asParams;
  }
}
