import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import {
  CommonSummary,
  CommonSummaryView,
  DynamicSummary,
  DynamicSummaryView,
} from './types';
import {
  formatCommonSummary,
  formatDynamicSummary,
} from '@/stores/domain/summary/helpers';
import summaryApi from '@/api/domain/summary';
import { Period } from '@/stores/core/Period';
import { YearQuarterParams } from '@/types/params';
import moment from 'moment';

export class DashboardStore {
  isLoading = false;
  common: CommonSummary | null = null;
  dynamic: DynamicSummary | null = null;

  private period: Period;
  private periodDisposer?: IReactionDisposer;

  constructor(period: Period) {
    makeAutoObservable(this);

    this.period = period;
  }

  get periodEndDays() {
    if (this.period.quarter !== moment(new Date()).quarter()) return null;

    const quarterEndDate = moment().endOf('quarter');
    return moment(quarterEndDate).diff(moment(new Date()), 'days');
  }

  get commonView(): CommonSummaryView {
    return formatCommonSummary(this.common);
  }

  get dynamicView(): DynamicSummaryView {
    return formatDynamicSummary(this.dynamic);
  }

  init() {
    this.periodDisposer = reaction(
      () => this.period.asParams,
      (params) => {
        this.fetch(params);
      }
    );
  }

  destroy() {
    this.periodDisposer && this.periodDisposer();
    this.reset();
  }

  reset() {
    this.isLoading = false;
    this.common = null;
    this.dynamic = null;
  }

  async fetch(params: YearQuarterParams = this.period.asParams) {
    this.isLoading = false;
    try {
      const [common, dynamic] = await Promise.all([
        summaryApi.getCommon(params),
        summaryApi.getDynamic({ year: params.year }),
      ]);

      this.common = common.data;
      this.dynamic = dynamic.data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
