<template>
  <value-wrap :class="$style.wrapper">
    <dtt-title tag="h3">{{
      $t('page_summary.dashboard.income.title')
    }}</dtt-title>
    <div :class="$style.grid">
      <dtt-value-card
        v-for="item in items"
        :key="item.header"
        :header="$t(item.header)"
        :value="item.value"
        :value-color="item?.color || ''"
        @click.native="item.onClick && item.onClick()"
        :style="{ cursor: item.onClick ? 'pointer' : 'default' }"
      >
        <template #header>
          <div :class="$style.header">
            <span class="dtt-value-card__header">
              {{ $t(item.header) }}
            </span>
            <dtt-hint :hint="$t(item.info)" area-y="top" />
          </div>
        </template>
      </dtt-value-card>
    </div>
  </value-wrap>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ValueWrap from '@/components/ui/ValueWrap.vue';
import { SummaryViewItem } from '@/stores/domain/summary/types';

@Component({
  components: { ValueWrap },
})
export default class DashboardGrid extends Vue {
  @Prop({ type: Array, default: () => [] })
  items!: SummaryViewItem[];
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2.4rem;

  & > *:first-child {
    grid-column: 1 / 4;
  }
}
.header {
  display: flex;
  align-items: center;

  & > *:last-child {
    margin-left: 1rem;
    margin-bottom: -0.5rem;
  }
}
</style>
