import { Period } from '@/stores/core/Period';
import { makeAutoObservable, reaction } from 'mobx';
import taxpayerApi from '@/api/domain/taxpayer';
import { Contractor } from '@/stores/domain/single-taxpayer/types';
import { formatContractor } from '@/stores/domain/single-taxpayer/helpers';
import { Filter } from '@/stores/core/Filter';

interface FilterState {
  tin: string;
  number: string;
  seller: boolean;
  page: number;
  size: number;
  sort: {
    prop: string;
    direction: 'asc' | 'desc';
  };
}

export class ContractorListStore {
  data: Contractor[] = [];
  isLoading = false;
  maxPage = 0;
  tin: string | null = null;
  filter = new Filter<FilterState>(
    {
      tin: '',
      number: '',
      seller: false,
      page: 0,
      size: 20,
      sort: {
        prop: '',
        direction: 'asc',
      },
    },
    { debounced: ['tin', 'number'] }
  );

  private periodDisposer?: () => void;
  private filterDisposer?: () => void;

  constructor(private period: Period) {
    makeAutoObservable(this);
  }

  init(tin: string) {
    this.tin = tin;
    this.filterDisposer = this.filter.watch(() => this.fetch());
    this.periodDisposer = reaction(
      () => this.period.asParams,
      () => {
        this.filter.setSilent({ page: 0 });
        this.fetch();
      }
    );
  }

  destroy() {
    this.filterDisposer && this.filterDisposer();
    this.periodDisposer && this.periodDisposer();
    this.isLoading = false;
    this.tin = null;
    this.filter.reset();
  }

  async fetch() {
    this.isLoading = true;
    try {
      const response = await taxpayerApi.getContractors(
        this.getParams(),
        this.getBody()
      );

      this.maxPage = response.data.totalPages - 1;
      this.data = response.data.content;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  private getParams() {
    const { page, seller, size, sort, tin } = this.filter.value;
    return {
      ...this.period.asParams,
      ...(tin && { tin }),
      pageNum: page,
      pageSize: size,
      ...(sort.prop && {
        columnSort: sort.prop,
        order: sort.direction.toUpperCase(),
      }),
      seller,
    };
  }

  private getBody() {
    return {
      tin: this.tin,
    };
  }
}

export class ContractorListViewStore {
  constructor(private readonly list: ContractorListStore) {
    makeAutoObservable(this);
  }

  get data() {
    return this.list.data.map(formatContractor);
  }
}
