import {
  Invoice,
  InvoiceFee,
  InvoiceFeeView,
  InvoiceView,
  SingleInvoice,
  SingleInvoiceView,
} from '@/stores/domain/invoices/types';
import { formatDate, handleNil } from '@/utils/format';
import { Option } from '@/types/option';
import {
  DocType,
  InvoiceDocTypeColor,
  InvoiceDocTypeLabel,
  InvoiceStatus,
  InvoiceStatusColor,
  InvoiceStatusLabel,
  PaymentStatusColor,
  PaymentStatusLabel,
  PaymentStatusType,
} from '@/stores/domain/invoices/constants';
import { formatNumber } from '@/utils/numbers';
import { i18n } from '@/i18n';
import { parseArrayLikeString } from '@/utils/string';

export const formatInvoiceStatus = (
  raw: InvoiceStatus
): Option<InvoiceStatus> => {
  return {
    label: i18n.t(InvoiceStatusLabel[raw]) as string,
    value: raw,
    color: InvoiceStatusColor[raw],
  };
};

export const formatInvoiceDocType = (raw: DocType): Option<DocType> => {
  return {
    label: i18n.t(InvoiceDocTypeLabel[raw]) as string,
    value: raw,
    color: InvoiceDocTypeColor[raw],
  };
};

export const formatInvoicePaymentStatus = (
  raw: PaymentStatusType
): Option<PaymentStatusType> => {
  const localeKey = PaymentStatusLabel[raw];
  return {
    label: (i18n.te(localeKey) ? i18n.t(localeKey) : '—') as string,
    value: raw,
    color: PaymentStatusColor[raw],
  };
};

export const formatInvoice = (raw: Invoice, seller = true): InvoiceView => {
  const sellerName = handleNil(raw.sellerName, parseArrayLikeString, [
    '—',
  ]).join(', ');
  const sellerTin = handleNil(raw.salerTin, parseArrayLikeString, ['—']);
  const customerName = handleNil(raw.customerName, parseArrayLikeString, [
    '—',
  ]).join(', ');
  const customerTin = handleNil(raw.customerTin, parseArrayLikeString, ['—']);
  return {
    invoiceNumber: handleNil(raw.invoiceNumber),
    date: handleNil(raw.date, formatDate),
    invoiceTurnoverDate: handleNil(raw.invoiceTurnoverDate, formatDate),
    documentId: handleNil(raw.documentId),
    invoiceRegNum: handleNil(raw.invoiceRegNum),
    correctedDocumentId: handleNil(raw.correctedDocumentId),
    selerTin: sellerTin,
    sellerName: sellerName,
    customerTin: customerTin,
    customerName: customerName,
    costExcludingVAT: handleNil(raw.costExcludingVAT, formatNumber),
    invoiceExcise: handleNil(raw.invoiceExcise, formatNumber),
    invoiceTurnoverSize: handleNil(raw.invoiceTurnoverSize, formatNumber),
    vatAmount: handleNil(raw.vatAmount, formatNumber),
    costIncludingVAT: handleNil(raw.costIncludingVAT, formatNumber),
    invoiceStatus: formatInvoiceStatus(raw.invoiceStatus),
    documentType: formatInvoiceDocType(raw.documentType),
    paymentStatus: formatInvoicePaymentStatus(raw.paymentStatus),
    contractorName: seller ? sellerName : customerName,
    contractorTin: seller ? sellerTin : customerTin,
    contractNum: handleNil(raw.contractNum),
    contractDate: handleNil(raw.contractDate, formatDate),
  };
};

export const formatSingleInvoice = (raw: SingleInvoice): SingleInvoiceView => {
  return {
    buyerId: handleNil(raw.buyerId, parseArrayLikeString, ['—']).join(', '),
    buyerName: handleNil(raw.buyerName, parseArrayLikeString, ['—']).join(', '),
    correctedDocumentId: handleNil(raw.correctedDocumentId),
    costExcludingVAT: handleNil(raw.costExcludingVAT, formatNumber),
    costIncludingVAT: handleNil(raw.costIncludingVAT, formatNumber),
    currency: handleNil(raw.currency),
    date: handleNil(raw.date, formatDate),
    docType: formatInvoiceDocType(raw.docType),
    documentId: handleNil(raw.documentId),
    documentStatus: formatInvoiceStatus(raw.documentStatus),
    invoiceExcise: handleNil(raw.invoiceExcise, formatNumber),
    invoiceNumber: handleNil(raw.invoiceNumber),
    invoiceRegNum: handleNil(raw.invoiceRegNum),
    invoiceTurnoverDate: handleNil(raw.invoiceTurnoverDate, formatDate),
    invoiceTurnoverSize: handleNil(raw.invoiceTurnoverSize, formatNumber),
    paymentStatus: formatInvoicePaymentStatus(raw.paymentStatus),
    relatedInvoiceRegNum: handleNil(raw.relatedInvoiceRegNum),
    sellerId: handleNil(raw.sellerId, parseArrayLikeString, ['—']).join(', '),
    sellerName: handleNil(raw.sellerName, parseArrayLikeString, ['—']).join(
      ', '
    ),
    vatAmount: handleNil(raw.vatAmount, formatNumber),
    vatRate: handleNil(raw.vatRate, formatNumber),
    contractNum: handleNil(raw.contractNum),
    contractDate: handleNil(raw.contractDate, formatDate),
  };
};

export const formatInvoiceFee = (raw: InvoiceFee): InvoiceFeeView => {
  return {
    catalogTruId: handleNil(raw.catalogTruId),
    description: handleNil(raw.description),
    exciseAmount: handleNil(raw.exciseAmount, formatNumber),
    exciseRate: handleNil(raw.exciseRate, formatNumber),
    ndsAmount: handleNil(raw.ndsAmount, formatNumber),
    ndsRate: handleNil(raw.ndsRate, formatNumber),
    priceWithTax: handleNil(raw.priceWithTax, formatNumber),
    priceWithoutTax: handleNil(raw.priceWithoutTax, formatNumber),
    productDeclaration: handleNil(raw.productDeclaration),
    productNumberInSnt: handleNil(raw.productNumberInSnt),
    quantity: handleNil(raw.quantity, formatNumber),
    truOriginCode: handleNil(raw.truOriginCode),
    turnoverSize: handleNil(raw.turnoverSize, formatNumber),
    unitCode: handleNil(raw.unitCode),
    unitNomenclature: handleNil(raw.unitNomenclature),
    unitPrice: handleNil(raw.unitPrice, formatNumber),
  };
};
