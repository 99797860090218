import { RouteConfig } from 'vue-router';

const BankOperationsView = () => import('@/views/app/bank-operations.vue');
const BankOperationsIndexView = () =>
  import('@/views/app/bank-operations/index.vue');

const bankOperationsRoutes: Array<RouteConfig> = [
  {
    path: 'bank-operations',
    component: BankOperationsView,
    children: [
      {
        path: '',
        name: 'bank-operations-index',
        component: BankOperationsIndexView,
      },
    ],
  },
];

export default bankOperationsRoutes;
