<template>
  <div>
    <slot />
    <span
      v-if="delta !== undefined"
      :class="[$style.text, isPositive ? 'text-success' : 'text-danger']"
    >
      {{ view }}
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatNumber } from '@/utils/numbers';

@Component
export default class NumberDeltaWrap extends Vue {
  @Prop({ type: Number }) delta?: number;

  get isPositive() {
    if (!this.delta) return true;
    return this.delta >= 0;
  }

  get view() {
    if (!this.delta) return '';
    return `${this.isPositive ? '+' : '-'} ${formatNumber(
      Math.abs(this.delta)
    )}`;
  }
}
</script>

<style lang="scss" module>
.text {
  @include dtt-typo-size(md, true);
}
</style>
