import { BankListStore } from '@/stores/domain/violations/BankListStore';
import { TaxpayerListStore } from '@/stores/domain/violations/TaxpayerListStore';
import { BankListViewStore } from '@/stores/domain/violations/BankListViewStore';
import { TaxpayerListViewStore } from '@/stores/domain/violations/TaxpayerListViewStore';
import { Period } from '@/stores/core/Period';

export class ViolationsStore {
  bankList: BankListStore;
  bankListView: BankListViewStore;
  taxpayerList: TaxpayerListStore;
  taxpayerListView: TaxpayerListViewStore;

  constructor(period: Period) {
    this.bankList = new BankListStore(period);
    this.bankListView = new BankListViewStore(this.bankList);
    this.taxpayerList = new TaxpayerListStore(period);
    this.taxpayerListView = new TaxpayerListViewStore(this.taxpayerList);
  }
}
