import { i18n } from '@/i18n';

type NumGroupConfig = {
  incorrectSign?: string;
  fraction?: boolean;
  fractionDigits?: number;
};
const defaultConfig: NumGroupConfig = {
  incorrectSign: '—',
  fraction: true,
  fractionDigits: 2,
};

export function formatNumber(
  value: number | null | undefined | string,
  options?: NumGroupConfig
) {
  const opts = Object.assign(
    { ...defaultConfig },
    options || {}
  ) as Required<NumGroupConfig>;
  if (
    value === null ||
    value === undefined ||
    typeof value !== 'number' ||
    isNaN(value)
  )
    return opts.incorrectSign;
  return (typeof value === 'number' ? value : +value).toLocaleString('ru', {
    ...(opts.fraction && {
      minimumFractionDigits: opts.fractionDigits,
      maximumFractionDigits: opts.fractionDigits,
    }),
  });
}

export function shortNumber(n: number) {
  const data = [
    {
      label: i18n.t('num.trillion'),
      limit: 1e12,
    },
    {
      label: i18n.t('num.billion'),
      limit: 1e9,
    },
    {
      label: i18n.t('num.million'),
      limit: 1e6,
    },
    {
      label: i18n.t('num.thousand'),
      limit: 1e3,
    },
  ];
  for (const item of data) {
    if (n >= item.limit) {
      const val = n / item.limit;
      return `${formatNumber(val, { fractionDigits: 1 })} ${
        item.label
      }`.replace(',0', '');
    }
  }
  return formatNumber(n, { fractionDigits: 0 });
}
