<template>
  <div v-if="days !== null" :class="$style.card">
    <dtt-title tag="h3" bold>
      {{ $t('page_summary.danger', { days }) }}
    </dtt-title>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DangerToast extends Vue {
  @Prop({ type: Number, default: null }) days!: number | null;
}
</script>

<style lang="scss" module>
.card {
  border-radius: 8px;
  padding: 2.8rem 3.2rem;
  border: 1px solid $--color-status-3;

  h3 {
    color: $--color-status-3;
  }
}
</style>
