import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import IndexView from '@/views/index.vue';
import AppView from '@/views/app.vue';
import SummaryView from '@/views/app/summary.vue';

import invoicesRoutes from '@/router/invoices';
import receiptsRoutes from '@/router/receipts';
import bankOperationsRoutes from '@/router/bank-operations';
import taxpayersRoutes from '@/router/taxpayers';
import statisticsRoutes from '@/router/statistics';
import violationsRoutes from '@/router/violations';
import banksRoutes from '@/router/banks';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    redirect: { name: 'summary' },
    component: IndexView,
  },
  {
    path: '/app',
    component: AppView,
    children: [
      {
        path: '',
        name: 'app-index',
        redirect: { name: 'summary' },
      },
      {
        path: 'summary',
        name: 'summary',
        component: SummaryView,
      },
      ...invoicesRoutes,
      ...receiptsRoutes,
      ...bankOperationsRoutes,
      ...taxpayersRoutes,
      ...statisticsRoutes,
      ...violationsRoutes,
      ...banksRoutes,
    ],
  },
];

const router = new VueRouter({
  mode: 'hash',
  routes,
});

export default router;
