import { makeAutoObservable } from 'mobx';
import {
  formatReceiptsInfo,
  formatReceiptStatistic,
} from '@/stores/domain/single-taxpayer/helpers';
import { ListStore } from '@/stores/domain/receipts/ListStore';
import { i18n } from '@/i18n';

export class ReceiptViewStore {
  constructor(private readonly model: ListStore) {
    makeAutoObservable(this);
  }

  get info() {
    if (!this.model.info) return null;
    return formatReceiptsInfo(this.model.info);
  }

  get statistics() {
    return this.model.statistics.map(formatReceiptStatistic);
  }

  get revenue() {
    if (!this.info) return null;
    const prefix = (path: string) =>
      i18n.t(`page_receipts.dashboard.revenue.${path}`);
    return {
      label: prefix('title'),
      data: [
        {
          header: prefix('sum'),
          value: this.info.revenueTotal,
          color: '#4F79D0',
        },
        {
          header: prefix('online_pay'),
          value: this.info.revenueNonCash,
          info: prefix('online_pay_info'),
          // delta: this.model.revenueDelta,
        },
        {
          header: prefix('offline_pay'),
          value: this.info.revenueCash,
        },
        {
          header: prefix('acquiring'),
          value: this.info.acquiringCash,
        },
      ],
    };
  }

  get vat() {
    if (!this.info) return null;
    const prefix = (path: string) =>
      i18n.t(`page_receipts.dashboard.vat.${path}`);
    return {
      label: prefix('title'),
      data: [
        {
          header: prefix('sum'),
          value: this.info.vatTotal,
          color: '#4F79D0',
        },
        {
          header: prefix('online_pay'),
          value: this.info.vatNonCash,
          info: prefix('online_pay_info'),
          // delta: this.model.vatDelta,
        },
        {
          header: prefix('offline_pay'),
          value: this.info.vatCash,
        },
        {
          header: prefix('acquiring'),
          value: this.info.vatAcquiring,
        },
      ],
    };
  }
}
