import { makeAutoObservable } from 'mobx';
import { formatTaxpayerViolation } from '@/stores/domain/violations/helpers';
import { TaxpayerListStore } from '@/stores/domain/violations/TaxpayerListStore';

export class TaxpayerListViewStore {
  private list: TaxpayerListStore;

  constructor(list: TaxpayerListStore) {
    makeAutoObservable(this);
    this.list = list;
  }

  get data() {
    return this.list.data.map(formatTaxpayerViolation);
  }
}
