import { RouteConfig } from 'vue-router';

const ReceiptsView = () => import('@/views/app/receipts.vue');
const ReceiptsIndexView = () => import('@/views/app/receipts/index.vue');

const receiptsRoutes: Array<RouteConfig> = [
  {
    path: 'receipts',
    component: ReceiptsView,
    children: [
      {
        path: '',
        name: 'receipts-index',
        component: ReceiptsIndexView,
      },
    ],
  },
];

export default receiptsRoutes;
