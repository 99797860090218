import { baseApi } from '@/api/base';
import { PageableResponse } from '@/types/pagination';
import { Invoice, SingleInvoice } from '@/stores/domain/invoices/types';
import { toQuery } from '@/utils/query';

const prefix = '/invoices';

const invoiceApi = {
  getList: (params: any, body: any = {}) =>
    baseApi.post<PageableResponse<Invoice>>(
      `${prefix}/no${toQuery(params)}`,
      body
    ),
  getOne: (id: string) =>
    baseApi.post<SingleInvoice[]>(`${prefix}/no/single`, { invoiceId: id }),
};

export default invoiceApi;
