import { baseApi } from '@/api/base';
import { toQuery } from '@/utils/query';
import { CommonSummary, DynamicSummary } from '@/stores/domain/summary/types';
import { YearQuarterParams, YearParams } from '@/types/params';

const prefix = '/commons/no';

const summaryApi = {
  getCommon: (params: YearQuarterParams) =>
    baseApi.get<CommonSummary>(`${prefix}/common${toQuery(params)}`),
  getDynamic: (params: YearParams) =>
    baseApi.get<DynamicSummary>(`${prefix}/dynamic${toQuery(params)}`),
};

export default summaryApi;
