import { baseApi } from '@/api/base';
import { toQuery } from '@/utils/query';
import { PageableResponse } from '@/types/pagination';
import { Taxpayer } from '@/stores/domain/taxpayers/types';
import {
  Contractor,
  ReceiptsInfo,
  ReceiptStatistic,
  TaxpayerInfo,
} from '@/stores/domain/single-taxpayer/types';
import { Invoice } from '@/stores/domain/invoices/types';

const prefix = '/taxpayers/no';

const taxpayerApi = {
  getList: (params: any, body: any = {}) =>
    baseApi.post<PageableResponse<Taxpayer>>(
      `${prefix}/taxpayers${toQuery(params)}`,
      body
    ),
  getOne: (params: any) =>
    baseApi.post<TaxpayerInfo>(`${prefix}/card/taxpayer${toQuery(params)}`),
  getContractors: (params: any, body: any = {}) =>
    baseApi.post<PageableResponse<Contractor>>(
      `${prefix}/counterparty${toQuery(params)}`,
      body
    ),
  getInvoices: (params: any) =>
    baseApi.get<PageableResponse<Invoice>>(
      `${prefix}/invoices${toQuery(params)}`
    ),
  getReceiptsInfo: (params: any) =>
    baseApi.get<ReceiptsInfo>(`${prefix}/receipts${toQuery(params)}`),
  getReceiptsStatistic: (params: any) =>
    baseApi.get<ReceiptStatistic[]>(
      `${prefix}/statistic/receipts${toQuery(params)}`
    ),
};

export default taxpayerApi;
