import { Filter } from '@/stores/core/Filter';
import { computed, makeAutoObservable } from 'mobx';
import { i18n } from '@/i18n';

type ControlPeriodType = 'year' | 'quarter';
type ControlType = 'taxpayers' | 'products';

export interface ControlState {
  type: ControlType;
  periodType: ControlPeriodType;
  search: string;
  inn: string;
  page: number;
  size: number;
  sort: {
    prop: string;
    direction: 'asc' | 'desc';
  };
  quarter: number | null;
  year: number;
}

export class ControlStore {
  prefix = 'page_statistics.type';
  periodPrefix = 'page_statistics.period_type';

  filter = new Filter<ControlState>(
    {
      type: 'taxpayers',
      periodType: 'year',
      search: '',
      inn: '',
      page: 0,
      size: 20,
      sort: {
        prop: '',
        direction: 'asc',
      },
      quarter: null,
      year: new Date().getFullYear(),
    },
    {
      ignored: ['type'],
      guard: (value) => {
        const { periodType, quarter, year } = value;
        return !!(
          (periodType === 'year' && year) ||
          (periodType === 'quarter' && year && quarter)
        );
      },
    }
  );

  constructor() {
    makeAutoObservable(this);
  }

  get periodTypeOptions() {
    return [
      {
        label: i18n.t(`${this.periodPrefix}.year`),
        value: 'year',
      },
      {
        label: i18n.t(`${this.periodPrefix}.quarter`),
        value: 'quarter',
      },
    ];
  }

  get periodTypeLabel() {
    const currentPeriodType = this.filter.value.periodType;
    return (
      this.periodTypeOptions.find((item) => item.value === currentPeriodType)
        ?.label || ''
    );
  }

  get typeLabel() {
    const currentType = this.filter.value.type;
    return (
      this.typeOptions.find((type) => currentType === type.value)?.label || ''
    );
  }

  get typeOptions() {
    return [
      {
        label: i18n.t(`${this.prefix}.taxpayers`),
        value: 'taxpayers',
      },
      {
        label: i18n.t(`${this.prefix}.products`),
        value: 'products',
      },
    ];
  }

  reset() {
    this.filter.setSilent({
      page: 0,
      search: '',
      inn: '',
      sort: {
        prop: '',
        direction: 'asc',
      },
    });
  }

  getParams() {
    const { page, size, year, search, inn, sort, quarter } = this.filter.value;
    return {
      pageNum: page,
      pageSize: size,
      year: year,
      ...(search && { search }),
      ...(inn && { inn }),
      ...(quarter && { quarter }),
      ...(sort.prop && {
        columnSort: sort.prop,
        order: sort.direction.toUpperCase(),
      }),
    };
  }
}
