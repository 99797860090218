import { RouteConfig } from 'vue-router';

const View = () => import('@/views/app/statistics.vue');
const IndexView = () => import('@/views/app/statistics/index.vue');

const statisticsRoutes: Array<RouteConfig> = [
  {
    path: 'statistics',
    component: View,
    children: [
      {
        path: '',
        name: 'statistics-index',
        component: IndexView,
      },
    ],
  },
];

export default statisticsRoutes;
