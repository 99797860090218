import { Taxpayer, TaxpayerView } from '@/stores/domain/taxpayers/types';
import { handleNil } from '@/utils/format';
import { formatNumber } from '@/utils/numbers';
import {
  TaxpayerActivityLabel,
  TaxpayerActivityType,
  TaxpayerEscrowColor,
  TaxpayerEscrowLabel,
  TaxpayerEscrowType,
  TaxpayerRiskColor,
  TaxpayerRiskLabel,
  TaxpayerRiskType,
  TaxpayerRPROColor,
  TaxpayerRPROLabel,
  TaxpayerRPROType,
} from '@/stores/domain/taxpayers/constants';
import { Option } from '@/types/option';
import { i18n } from '@/i18n';

export const formatTaxpayerEscrow = (
  raw: TaxpayerEscrowType
): Option<TaxpayerEscrowType> => {
  return {
    label: i18n.t(TaxpayerEscrowLabel[raw]) as string,
    value: raw,
    color: TaxpayerEscrowColor[raw],
  };
};

export const formatTaxpayerRisk = (
  raw: TaxpayerRiskType
): Option<TaxpayerRiskType> => {
  return {
    label: i18n.t(TaxpayerRiskLabel[raw]) as string,
    value: raw,
    color: TaxpayerRiskColor[raw],
  };
};

export const formatTaxpayerRPRO = (
  raw: TaxpayerRPROType
): Option<TaxpayerRPROType> => {
  return {
    label: i18n.t(TaxpayerRPROLabel[raw]) as string,
    value: raw,
    color: TaxpayerRPROColor[raw],
  };
};

export const formatTaxpayerActivity = (
  raw: TaxpayerActivityType
): Option<TaxpayerActivityType> => {
  return {
    label: i18n.t(TaxpayerActivityLabel[raw]) as string,
    value: raw,
  };
};

export const formatTaxpayer = (raw: Taxpayer): TaxpayerView => {
  return {
    escrowAccountBalance: handleNil(raw.escrowAccountBalance, formatNumber),
    escrowAccountType: formatTaxpayerEscrow(raw.escrowAccountType),
    incomingVAT: handleNil(raw.incomingVAT, formatNumber),
    incomingVATNoInvoices: handleNil(raw.incomingVATNoInvoices, formatNumber),
    incomingVATPaidInvoices: handleNil(
      raw.incomingVATPaidInvoices,
      formatNumber
    ),
    incomingVATUnpaidInvoices: handleNil(
      raw.incomingVATUnpaidInvoices,
      formatNumber
    ),
    outgoingVAT: handleNil(raw.outgoingVAT, formatNumber),
    outgoingVATInvoices: handleNil(raw.outgoingVATInvoices, formatNumber),
    outgoingVATReceipts: handleNil(raw.outgoingVATReceipts, formatNumber),
    risk: formatTaxpayerRisk(raw.risk),
    rproStatus: formatTaxpayerRPRO(raw.rproStatus),
    status: formatTaxpayerActivity(raw.status),
    taxpayerInn: handleNil(raw.taxpayerInn),
    taxpayerName: handleNil(raw.taxpayerName),
    vatBalance: handleNil(raw.vatBalance, formatNumber),
    delta: handleNil(raw.delta, formatNumber),
  };
};
