<template>
  <div :class="$style.header">
    <period-selector />
    <div :class="$style['header-right']">
      <dtt-context-menu
        :class="$style.menu"
        :options="profileOptions"
        @change="contextMenuClick"
        use-value
      >
        <div :class="$style.profile">
          <dtt-title tag="h4">{{ $t('mock_user') }}</dtt-title>
          <div :class="$style.avatar">
            <dtt-icon name="profile" />
          </div>
          <dtt-icon name="arrow-down" height="2rem" width="2rem" />
        </div>
      </dtt-context-menu>
      <div :class="$style.dividor"></div>
      <dtt-icon name="information" />
      <div :class="$style.dividor"></div>
      <locale-switch />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import LocaleSwitch from '@/components/LocaleSwitch.vue';
import PeriodSelector from '@/components/PeriodSelector.vue';

@Component({
  components: { PeriodSelector, LocaleSwitch },
})
export default class TheHeader extends Vue {
  get profileOptions() {
    return [
      {
        label: this.$t('profile.exit'),
        value: 'exit',
        icon: 'arrow-to-the-right',
      },
    ];
  }
  async contextMenuClick(value: 'exit') {
    if (value === 'exit') {
      // TODO: handle exit
    }
  }
}
</script>

<style lang="scss" module>
.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &-right {
    display: flex;
    align-items: center;
  }
}

.profile {
  display: flex;
  align-items: center;
  cursor: pointer;

  & > * {
    flex: 0 0 auto;
  }

  h4 {
    color: $--color-primary-dark;
    margin-right: 0.8rem;
  }
}

.menu {
}

.avatar {
  flex: 0 0 auto;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  background-color: $--color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.4rem;

  svg {
    color: $--color-white;
  }
}

.dividor {
  margin: 0 2.4rem;
  height: 3.2rem;
  width: 1px;
  border: 1px solid $--color-neutral-300;
}
</style>
