import { ListStore } from '@/stores/domain/banks/ListStore';
import { formatBank } from '@/stores/domain/banks/helpers';

export class ListViewStore {
  private list: ListStore;

  constructor(list: ListStore) {
    this.list = list;
  }

  get data() {
    return this.list.data.map(formatBank);
  }
}
