export const MONTHS: { label: string; value: number }[] = [
  {
    label: 'months.january.full',
    value: 1,
  },
  {
    label: 'months.february.full',
    value: 2,
  },
  {
    label: 'months.march.full',
    value: 3,
  },
  {
    label: 'months.april.full',
    value: 4,
  },
  {
    label: 'months.may.full',
    value: 5,
  },
  {
    label: 'months.june.full',
    value: 6,
  },
  {
    label: 'months.july.full',
    value: 7,
  },
  {
    label: 'months.august.full',
    value: 8,
  },
  {
    label: 'months.september.full',
    value: 9,
  },
  {
    label: 'months.october.full',
    value: 10,
  },
  {
    label: 'months.november.full',
    value: 11,
  },
  {
    label: 'months.december.full',
    value: 12,
  },
];
