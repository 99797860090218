<template>
  <chart-js-line id="year-chart" :data="chartData" :options="chartOptions" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MONTHS } from '@/constants/months';
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  registerables,
} from 'chart.js';
import { QUARTERS } from '@/constants/quarters';
import { shortNumber } from '@/utils/numbers';

ChartJS.register(...registerables);

@Component({
  components: { ChartJsLine: Line },
})
export default class YearLineChart extends Vue {
  @Prop({ type: Array, default: () => [] }) data!: any[];
  @Prop({ type: Array, default: () => [] }) secondData!: any[];
  @Prop({ type: Array, default: () => [] }) thirdData!: any[];
  @Prop({ type: Array, default: () => [] }) labels!: string[];
  @Prop({ type: Boolean, default: false }) useQuarters!: boolean;
  @Prop({ type: Number }) min!: number;
  @Prop({ type: Number }) max!: number;

  get chartData() {
    return {
      labels: (this.useQuarters ? QUARTERS : MONTHS).map((m) =>
        this.$t(m.label)
      ),
      datasets: [
        {
          data: this.data,
          cubicInterpolationMode: 'monotone',
          fill: true,
          backgroundColor: '#9FB8EB30',
          borderColor: '#9FB8EB',
          borderWidth: 2,
          pointRadius: 5,
          pointBackgroundColor: '#fff',
          pointHoverRadius: 5,
          pointHoverBorderWidth: 2,
          spanGaps: true,
        },
        {
          data: this.secondData,
          cubicInterpolationMode: 'monotone',
          fill: true,
          backgroundColor: '#C8FF6630',
          borderColor: '#C8FF66',
          borderWidth: 2,
          pointRadius: 5,
          pointBackgroundColor: '#fff',
          pointHoverRadius: 5,
          pointHoverBorderWidth: 2,
          spanGaps: true,
        },
        {
          data: this.thirdData,
          cubicInterpolationMode: 'monotone',
          fill: true,
          backgroundColor: '#FED13D30',
          borderColor: '#FED13D',
          borderWidth: 2,
          pointRadius: 5,
          pointBackgroundColor: '#fff',
          pointHoverRadius: 5,
          pointHoverBorderWidth: 2,
          spanGaps: true,
        },
      ],
    } as ChartData;
  }

  get chartOptions() {
    const labels = this.labels;
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          min: this.min,
          max: this.max,
          ticks: {
            callback: function (value: number) {
              if (value < 1e9) return;
              const short = shortNumber(value);
              if (short.includes(',')) return;
              return shortNumber(value);
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          padding: 16,
          callbacks: {
            label(): string | string[] | void {
              return '';
            },
            beforeLabel(item: any): string | string[] | void {
              return `${labels[item.datasetIndex]}: ${item.formattedValue}`;
            },
          },
        },
      },
    } as ChartOptions;
  }
}
</script>
