import { makeAutoObservable, reaction } from 'mobx';
import { Invoice } from '@/stores/domain/invoices/types';
import { formatInvoice } from '@/stores/domain/invoices/helpers';
import { Period } from '@/stores/core/Period';
import { Filter } from '@/stores/core/Filter';
import invoiceApi from '@/api/domain/invoice';
import { PaymentStatusType } from '@/stores/domain/invoices/constants';

interface FilterState {
  seller: boolean;
  page: number;
  size: number;
  sort: {
    prop: string;
    direction: 'asc' | 'desc';
  };
  search: string;
  paymentStatuses: PaymentStatusType[];
}

export class InvoicesStore {
  data: Invoice[] = [];
  isLoading = false;
  maxPage = 0;
  tin: string | null = null;
  filter = new Filter<FilterState>(
    {
      seller: false,
      page: 0,
      size: 20,
      sort: {
        prop: '',
        direction: 'asc',
      },
      search: '',
      paymentStatuses: [],
    },
    { debounced: ['search'] }
  );

  private filterDisposer?: () => void;
  private periodDisposer?: () => void;

  constructor(private readonly period: Period) {
    makeAutoObservable(this);
  }

  init(tin: string) {
    this.tin = tin;
    this.periodDisposer = reaction(
      () => this.period.asParams,
      () => {
        this.filter.setSilent({ page: 0 });
        this.fetch();
      }
    );
    this.filterDisposer = this.filter.watch(() => this.fetch());
  }

  destroy() {
    this.tin = null;
    this.periodDisposer && this.periodDisposer();
    this.filterDisposer && this.filterDisposer();
    this.filter.setSilent({ paymentStatuses: [] });
    this.data = [];
    this.isLoading = false;
  }

  async fetch() {
    this.isLoading = true;
    try {
      const response = await invoiceApi.getList(
        this.getParams(),
        this.getBody()
      );

      this.maxPage = response.data.totalPages - 1;
      this.data = response.data.content;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  private getParams() {
    const { page, size, sort, search } = this.filter.value;
    return {
      ...this.period.asParams,
      pageNum: page,
      pageSize: size,
      search: search,
      ...(sort.prop && {
        columnSort: sort.prop,
        order: sort.direction.toUpperCase(),
      }),
    };
  }

  private getBody() {
    const { seller, paymentStatuses } = this.filter.value;
    return {
      [seller ? 'singleSellerTin' : 'singleCustomerTin']: this.tin,
      ...(paymentStatuses.length && { paymentStatuses }),
    };
  }
}

export class InvoicesViewStore {
  constructor(private readonly list: InvoicesStore) {
    makeAutoObservable(this);
  }

  get data() {
    return this.list.data.map((item) =>
      formatInvoice(item, !this.list.filter.value.seller)
    );
  }
}
